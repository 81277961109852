import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import FraudCaseForm from '../../modules/fraudCases/UI/FraudCaseForm';
import Button from '../../components/shared/Button';
import { BACK_TO_LIST } from '../../constants';
import { Link, useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'Unos slučaja prevare';

function FraudCaseFormPage() {
	const navigate = useNavigate();

	return (
    <>
     <span
					className='mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group'>
				<Button
					onClick={() => navigate(-1)}
								type='button'
								variant='back'
								tabIndex={-1}>
							{BACK_TO_LIST}
							</Button>
				</span>
      <Meta title={PAGE_TITLE} />
      <PageLayout title={PAGE_TITLE}>
        <FraudCaseForm />
      </PageLayout>
    </>
  );
}

export default FraudCaseFormPage;
