import { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LazyImage from '../../../components/shared/LazyImage';
import placeholder from '../../../assets/images/placeholders/news.png';
import placeholderCompact from '../../../assets/images/placeholders/news-square.png';
import placeholderLarge from '../../../assets/images/placeholders/news-large.png';
import { NEWS_PAGE_LINK, ROUTES } from '../../../constants';
import { ViewType, ViewEnum } from '../../../types';
import { NewsArticleInterface } from '../services/index.types';
import NewsStore from '../services/news.store';

/**
 * NewsListProps Interface
 *
 * Defines the props for the NewsList component.
 * @interface
 * @property {NewsArticleInterface[]} news - The array of news articles to display.
 * @property {ViewType} [view] - The view type for displaying the news, either compact, detailed, or grid.
 */
interface NewsListProps {
    news: NewsArticleInterface[];
    view?: ViewType;
}

const newsStore = new NewsStore();

const NewsList: React.FC<NewsListProps> = ({
    news,
    view = ViewEnum.Compact,
}: NewsListProps): React.JSX.Element => {
    const location = useLocation();
    const isNewsPage = /^\/education\/news(\/)?$/.test(location.pathname);
    const [images, setImages] = useState<{ [key: number]: string | null }>({});
    useEffect(() => {
        const fetchImages = async () => {
            const imagesMap: { [key: number]: string | null } = {};
            const fetchPromises = news.map(async (article) => {
                if (article.id && article.have_main_photo && !images[article.id]) { 
                    const response = await newsStore.useGetNewsPhoto(article.id);
                    if (response.success) {
                        imagesMap[article.id] = response.data; 
                    } else {
                        imagesMap[article.id] = null; 
                    }
                }
            });
            
            await Promise.all(fetchPromises); 
			setImages((prevImages) => ({ ...prevImages, ...imagesMap })); 
        };

        if (news.length > 0) {
            fetchImages(); 
        }
    }, [news]); 

    const eagerImgsNumRef = useRef<number | undefined>();

    if (typeof window !== 'undefined') {
        const screenWidth = window.innerWidth;
        eagerImgsNumRef.current = screenWidth < 1024 ? 0 : 5;
    }

    const newsHolderClassName = `pks-layout-col-md ${
        view === ViewEnum.Compact
            ? ''
            : `sm:grid sm:grid-cols-2 ${
                  view === 'detailed' ? 'lg:grid-cols-1' : ''
              }`
    }`;

    const newsItemClassName = `group ${
        view === ViewEnum.Compact
            ? 'flex items-start gap-2 pb-4 border-b border-b-secondary-200'
            : ''
    }`;

    const newsImgHolderClassName = `relative overflow-hidden rounded-lg bg-gray-300 ${
        view === ViewEnum.Compact ? 'flex-none aspect-square' : 'aspect-video mb-4'
    }`;

    const newsImgClassName = `object-cover animate-fade-in ${
        view === ViewEnum.Compact
            ? 'w-[60px] h-[60px]'
            : 'w-full h-full transition-transform transform group-hover:scale-105'
    }`;

    const newsTitleClassName = 'overflow-ellipses line-clamp-2 group-hover:text-primary-300 h3';

    return (
        <ul className={newsHolderClassName}>
            {news?.map((article, index) => {
                const firstGridItemClassName = view === 'grid' && index === 0 ? 'col-span-full' : 'w-full';

                const placeholderImage =
                    view === ViewEnum.Compact
                        ? placeholderCompact
                        : view === 'grid' && index === 0
                        ? placeholderLarge
                        : placeholder;

                const imageUrl = images[article.id!] || placeholderImage;

                return (
                    <li key={article.id || index} className={firstGridItemClassName}>
                        <Link
                            to={`${NEWS_PAGE_LINK}/${article.id}`}
                            state={{backTo: isNewsPage ? `${location.pathname}${location.search}` : "/education/news"}}
                            title={article.title}
                            className={newsItemClassName}
                        >
                            <div className={newsImgHolderClassName}>
                                <LazyImage
                                    alt={article.title || 'Nedostupna slika'}
                                    src={imageUrl}
                                    loading={
                                        eagerImgsNumRef.current !== undefined &&
                                        index < eagerImgsNumRef.current
                                            ? 'eager'
                                            : 'lazy'
                                    }
                                    className={newsImgClassName}
                                />
                            </div>
                            <h2 className={newsTitleClassName}>{article.title}</h2>
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};

export default NewsList;
