import {
	FieldValues,
	FormProvider,
	SubmitHandler,
	useForm,
} from 'react-hook-form';
import FileInput from '../../../components/shared/FileInput';
import Icon from '../../../components/shared/Icon';

import { formatDateWithPoints } from '../../../utils';
import FraudCaseStore from '../services/fraudCases.store';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
const fraudCaseStore: FraudCaseStore = new FraudCaseStore();

const FraudCaseSingleDetails = ({
	id,
	data: fraudCaseDetailsData,
}: {
	id?: string;
	data: any;
}) => {
	//const fraudCaseDetailsData: any = useLoaderData();

	const inputDateStart = formatDateWithPoints(
		fraudCaseDetailsData?.date_created?.date
	);
	const inputDateEnd = formatDateWithPoints(
		fraudCaseDetailsData?.input_date_end?.date
	);
	const fraudDateStart = formatDateWithPoints(
		fraudCaseDetailsData?.fraud_date_start?.date
	);
	const fraudDateEnd = formatDateWithPoints(
		fraudCaseDetailsData?.fraud_date_end?.date
	);

	// Use the `useGetFraudCaseFiles` hook from the fraud cases store to fetch fraud case files data
	const { data: fraudCaseFilesData } = fraudCaseStore.useGetFraudCaseFiles(
		Number(id)
	);
	const allFiles = fraudCaseFilesData?._embedded?.files || [];

	// Use the `useGetFraudCase` hook from the fraud cases store to fetch fraud case type data
	const { data: fraudCaseTypesData } = fraudCaseStore.useGetFraudCasesTypes();
	const sourceType = fraudCaseDetailsData?.source_type;
	const type = fraudCaseTypesData?.[fraudCaseDetailsData?.type_id];
	// Use the `useGetFraudCase` hook from the fraud cases store to fetch fraud case card type data
	const { data: fraudCaseCardTypesData } =
		fraudCaseStore.useGetFraudCardTypes();
	const cardTypes =
		fraudCaseCardTypesData?.[fraudCaseDetailsData?.card_type_names];

	// Use the `useGetFraudCasesTerminalTypes` hook from the fraud cases fetch fraud case terminal type data
	const { data: fraudCaseTerminalTypesData } =
		fraudCaseStore.useGetFraudCasesTerminalTypes();

	const terminalType =
		fraudCaseTerminalTypesData?.[fraudCaseDetailsData?.terminal_type_id];

	const openFile = async (fileId: number) => {
		try {
			const url = await fraudCaseStore.getFraudCaseFile(fileId);
			if (!url) {
				throw new Error('No URL found');
			}
			window.open(url, '_blank');
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const [loadingFiles, setLoadingFiles] = useState<{
		[key: number]: boolean;
	}>({});

	const deleteFile = async (fileId: number) => {
		try {
			setLoadingFiles((prev) => ({ ...prev, [fileId]: true }));
			await fraudCaseStore.deleteFraudCaseFile(fileId);
		} catch (error) {
		} finally {
			setLoadingFiles({});
		}
	};

	const methods = useForm<FieldValues>({
		defaultValues: {
			documents: [],
		},
	});

	const { handleSubmit } = methods;

	const onSubmit: SubmitHandler<FieldValues> = async (data) => {
		const payload = {
			documents: [...data.documents],
		};
		try {
			await fraudCaseStore.addFraudCaseFiles(Number(id), payload);
			if (data.documents.length === 1)
				toast.success('Fajl je uspešno otpremljen.');
			if (data.documents.length > 1)
				toast.success('Fajlovi su uspešno otpremljeni.');
		} catch (error) {
			if (data.documents.length === 1)
				toast.success('Došlo je do greške prilikom otpremanja.');
		}
	};
	return (
		<div>
			<ul className='flex flex-col gap-1'>
				<li>
					<span>
						{sourceType === 1
							? 'Issuer'
							: sourceType === 2
							? 'Acquirer'
							: ''}
					</span>
				</li>
				<li>
					<span className='font-bold'>Vrsta prevare:</span> {type}
				</li>
				{fraudCaseDetailsData?.card_type_names &&
					Object.keys(fraudCaseDetailsData.card_type_names).length >
						0 && (
						<li>
							<span className='font-bold'>Tip kartice:</span>{' '}
							{Object.values(
								fraudCaseDetailsData.card_type_names
							).join(', ')}
						</li>
					)}
				<li>
					<span className='font-bold'>Tip terminala:</span>{' '}
					{terminalType}
				</li>
			</ul>

			<div className='pks-divider-dashed'></div>

			<ul className='flex flex-col gap-1'>
				<li>
					<span className='font-bold'>BIN:</span>{' '}
					{fraudCaseDetailsData?.card_number
						? '*'.repeat(fraudCaseDetailsData.card_number.length)
						: ''}
				</li>
				<li>
					<span className='font-bold'>Acquirer ID:</span>{' '}
					{fraudCaseDetailsData?.acquirer_id}
				</li>
				<li>
					<span className='font-bold'>MID:</span>{' '}
					{fraudCaseDetailsData?.mid}
				</li>
				<li>
					<span className='font-bold'>TID:</span>
					{fraudCaseDetailsData?.tid}
				</li>
			</ul>

			<div className='pks-divider-dashed'></div>

			<ul className='flex flex-col gap-1'>
				<li>
					<span className='font-bold'>Datum unosa:</span>{' '}
					{inputDateStart}
				</li>
				<li>
					<span className='font-bold'>Datum prevare:</span>{' '}
					{fraudDateStart}
				</li>
			</ul>

			<div className='pks-divider-dashed'></div>

			<ul className='flex flex-col gap-1'>
				<li>
					<span className='font-bold'>Region:</span>{' '}
					{fraudCaseDetailsData?.regions[0]?.name}
				</li>
			</ul>

			<div className='pks-divider-dashed'></div>

			<div>
				<p className='font-bold'>Otpremljeni fajlovi:</p>

				<ul className='flex flex-col gap-2'>
					<FormProvider {...methods}>
						<form onChange={handleSubmit(onSubmit)}>
							<FileInput
								showFiles={false}
								id='documents'
								name='documents'
								label='Dodaj dokument:'
								maxSizeMB={16}
								multiple
							/>
						</form>
					</FormProvider>
					{allFiles.length > 0 &&
						allFiles.map((file: any, index: number) => (
							<li key={index} className=''>
								<span className='flex items-center gap-4'>
									<button
										className='cursor-pointer underline'
										onClick={() => openFile(file.id)}>
										{file.filename}
									</button>
									{loadingFiles[file.id] ? (
										<span>
											<Icon
												name='loadingSpinner'
												className='flex items-center size-6'
											/>
										</span>
									) : (
										<button
											onClick={() => deleteFile(file.id)}>
											<Icon
												name='trash'
												className='flex items-center'
											/>
										</button>
									)}
								</span>
							</li>
						))}
				</ul>
			</div>

			<div className='pks-divider-dashed'></div>

			<div>
				<p className='font-bold'>Napomena/Opis:</p>
				<div>{fraudCaseDetailsData?.description}</div>
			</div>
		</div>
	);
};

export default FraudCaseSingleDetails;
