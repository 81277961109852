import { Link, useLocation, useNavigate } from 'react-router-dom';
import Meta from '../components/layout/Meta';
import Button from '../components/shared/Button';
import { BACK_TO_NEWS } from '../constants';
import PageLayout from '../layouts/PageLayout';
import NewsForm from '../modules/news/UI/NewsForm';

function NewsEntry() {
	const navigate = useNavigate()
	const pageTitle = 'Unos nove vesti';
	const location = useLocation();
	const handleBack = () => {
		const backTo = location.state?.backTo || "/education/news";  
		navigate(backTo)
	  };
	return (
		<>
			<span
				
					className='mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group'>
							<Button
							onClick={handleBack}
								type='button'
								variant='back'
								tabIndex={-1}>
							{BACK_TO_NEWS}
							</Button>
				</span>
			<Meta title={pageTitle} />
			<PageLayout title={pageTitle}>
			    <NewsForm/>
            </PageLayout>
		</>
	);
}

export default NewsEntry;
