import { Link, useParams } from 'react-router-dom';
import Meta from '../components/layout/Meta';
import PageLayout from '../layouts/PageLayout';
import PageTitleWithActions from '../layouts/PageLayout/TitleWithActions';
import Button from '../components/shared/Button';
import EducationReports from '../modules/educationReports/UI';

const pageHeadings = [
    {
        id: 'regular_sessions',
        title: 'Redovne sednice Foruma',
    },
    {
        id: 'regional_sessions',
        title: 'Regionalne sednice foruma',
    },
    {
        id: 'conferences',
        title: 'Konferencije sa učešćem Foruma',
    },
    {
        id: 'seminars_by_forum',
        title: 'Seminari u organizaciji Foruma',
    },
    {
        id: 'seminars_participation',
        title: 'Seminari sa učešćem Foruma',
    },
    {
        id: 'education',
        title: 'Edukacije u organizaciji Foruma',
    },
    {
        id: 'east',
        title: 'EAST',
    },
    {
        id: 'laws',
        title: 'Zakoni i propisi',
    },
];

function EducationReportsPage() {
    const { slug } = useParams<{ slug: string }>(); 
    const currentPage = pageHeadings.find(heading => heading.id === slug); 
    const pageTitle = currentPage ? currentPage.title : 'Edukacija i korisne informacije'; 

    return (
        <>
            <Meta title='Edukacija i korisne informacije' />
            <PageLayout>
                <PageTitleWithActions title={pageTitle}>
                    <Link
                        to='/education/add-document'
                        state={{currentType: slug}}
                        replace
                        className='focus:outline-none group'>
                        <Button tabIndex={-1}>Novi dokument</Button>
                    </Link>
                </PageTitleWithActions>
                <EducationReports />
            </PageLayout>
        </>
    );
}

export default EducationReportsPage;
