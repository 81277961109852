/**
 * Add TypeScript
 * */
import TableCellContent from './TableCellContent';

import { TableHeadingType, TableCellInterface } from '../../../types';
import { formatDateWithPoints } from '../../../utils';
import NewsStore from '../../../modules/news/services/news.store';
import { useState } from 'react';
import TransitionLoader from '../TransitionLoader/TransitionLoader';

/** Table Interface */
interface TableProps {
  headings: TableHeadingType[];
  rows: any;
  handleRowClick?: (e: any, row: any) => void;
}
const newsStore = new NewsStore();

/** Table Component */
const Table: React.FC<TableProps> = ({ headings, rows, handleRowClick }) => {
  const [isLoading, setIsLoading] = useState(false);

  const openFile = async (e: any, fileId: number, type: string) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      const url = await newsStore.getDocument(fileId, type);
      if (!url) {
        throw new Error('No URL found');
      }
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative overflow-x-auto rounded-lg border border-secondary-200">
      <table className="w-full rtl:text-right">
        <thead className="text-left bg-secondary-200 text-secondary-400">
          <tr>
            {headings.map((heading, index) => (
              <th key={index} scope="col" className={`p-4 font-normal whitespace-nowrap ${index === headings.length - 1 ? 'pr-4 text-center' : 'pr-6'}`.trim()}>
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading && <TransitionLoader message="Preuzimanje fajla..." />}

          {rows.map((row: any, rowIndex: number) => {
            return (
              <tr
                onClick={(e) => handleRowClick && handleRowClick(e, row)}
                key={rowIndex}
                className={`bg-white text-left cursor-pointer hover:bg-primary-100 ${rowIndex !== rows.length - 1 ? 'border-b border-b-secondary-200' : ''}`.trim()}
              >
                <td className="p-4 pr-4   whitespace-nowrap" onClick={(e) => openFile(e, row.id, row.type)}>
                  <TableCellContent cell={{ content: row?.type, type: 'type' }} />
                </td>
                <td className="p-4 pr-4 whitespace-nowrap">
                  <TableCellContent cell={{ content: row?.title, type: 'title' }} />
                </td>
                <td className="p-4 pr-4 whitespace-nowrap">
                  <TableCellContent cell={{ content: row?.details, type: 'details' }} />
                </td>
                <td className="p-4 pr-4 whitespace-nowrap text-center">
                  <TableCellContent cell={{ content: formatDateWithPoints(row?.date_created.date), type: 'date_created' }} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
