import { useState } from "react";
import Button from "../../../components/shared/Button";
import NewsStore from "../../news/services/news.store";
import { formatDateWithPoints } from "../../../utils";


const newsStore = new NewsStore()

const DocumentModalContent = ({ document }: any) => {
  const [isLoading, setIsLoading] = useState(false)
	const emptyPropText = '/';
  
    const openFile = async (fileId: number, type: string) => {
        setIsLoading(true)
        try {
            const url = await newsStore.getDocument(fileId, type);
          if (!url) {
            throw new Error('No URL found');
          }
          window.open(url, '_blank');
        } catch (error) {
          console.error('Error:', error);
        } finally {
        setIsLoading(false)
            
        }
      };

    const className = 'h3 font-normal flex flex-col gap-1 ';
    return (
      <>
        <ul className="space-y-3">
          <li className={className}>
            <b>Naslov</b> {document?.title || emptyPropText}
          </li>
          <li className={className}>
            <b>Opis</b> {document.details || emptyPropText}
          </li>
          <li className={className}>
            <b>Tip dokumenta</b> {document.type || emptyPropText}
          </li>
          <li className={className}>
            <b>Kreirano od</b> {document.user_created?.firstName + ' ' + document.user_created?.lastName || emptyPropText}
          </li>
          <li className={className}>
            <b>Datum unosa</b> {formatDateWithPoints(document.date_created.date) || emptyPropText}
          </li>

          <li className={className}>
            <b>{document.regions.length <= 1 ? 'Region' : 'Regioni'}</b>
            <div className="flex flex-wrap gap-2">
              {document.regions.length === 0 ? (
                <p>{emptyPropText}</p>
              ) : (
                document.regions.map((region: any, i: number) => (
                  <p key={i} className="bg-secondary-100 w-fit py-1 px-2 rounded-lg">
                    {region.name}
                  </p>
                ))
              )}
            </div>
          </li>
        </ul>
        <div className="mt-8">
          <Button isDisabled={isLoading} onClick={() => openFile(document.id, document.type)}>
            {isLoading ? 'Otvaranje fajla...' : 'Otvori fajl'}
          </Button>
        </div>
      </>
    );
  };
  
  export default DocumentModalContent;
  