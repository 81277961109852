import { DropDownItemInterface } from '../types';

export const FREUD_CASE_OPTIONS: DropDownItemInterface[] = [
	{
		id: 'fc-00',
		label: 'Sve vrste prevara',
		index: true,
	},
	{
		id: 'fc-01',
		label: 'Fraud ISS',
	},
	{
		id: 'fc-02',
		label: 'Zloupotreba kartica',
	},
	{
		id: 'fc-03',
		label: 'Kompromitacija podataka',
	},
	{
		id: 'fc-04',
		label: 'Test merchant',
	},
	{
		id: 'fc-05',
		label: 'Fraud ACQ',
	},
];

export const SERBIA_REGIONS_OPTIONS: DropDownItemInterface[] = [
	{
		id: 'r-01',
		label: 'Beograd',
	},
	{
		id: 'r-02',
		label: 'Novi Sad',
	},
	{
		id: 'r-03',
		label: 'Niš',
	},
	{
		id: 'r-04',
		label: 'Kragujevac',
	},
	{
		id: 'r-05',
		label: 'Subotica',
	},
	{
		id: 'r-06',
		label: 'Zrenjanin',
	},
	{
		id: 'r-07',
		label: 'Pančevo',
	},
	{
		id: 'r-08',
		label: 'Čačak',
	},
	{
		id: 'r-09',
		label: 'Kruševac',
	},
	{
		id: 'r-10',
		label: 'Kraljevo',
	},
	{
		id: 'r-11',
		label: 'Sombor',
	},
	{
		id: 'r-12',
		label: 'Užice',
	},
	{
		id: 'r-13',
		label: 'Vranje',
	},
	{
		id: 'r-14',
		label: 'Valjevo',
	},
	{
		id: 'r-15',
		label: 'Šabac',
	},
	{
		id: 'r-16',
		label: 'Požarevac',
	},
	{
		id: 'r-17',
		label: 'Smederevo',
	},
	{
		id: 'r-18',
		label: 'Leskovac',
	},
	{
		id: 'r-19',
		label: 'Zaječar',
	},
	{
		id: 'r-20',
		label: 'Sremska Mitrovica',
	},
];

export const ROLE_OPTIONS: DropDownItemInterface[] = [
	{
		id: 'r-1',
		label: 'Admin PKS',
	},
	{
		id: 'r-2',
		label: 'Admin Banka',
	},
];

export const INSTITUTION_OPTIONS: DropDownItemInterface[] = [
	{
		id: 1,
		label: 'Univerzitet u Beogradu',
	},
	{
		id: 'i-02',
		label: 'Univerzitet u Novom Sadu',
	},
	{
		id: 'i-03',
		label: 'Univerzitet u Nišu',
	},
	{
		id: 'i-04',
		label: 'Univerzitet u Kragujevcu',
	},
	{
		id: 'i-05',
		label: 'Narodna biblioteka Srbije',
	},
	{
		id: 'i-06',
		label: 'Srpska akademija nauka i umetnosti',
	},
	{
		id: 'i-07',
		label: 'Institut za nuklearne nauke Vinča',
	},
	{
		id: 'i-08',
		label: 'Biblioteka Matice srpske',
	},
	{
		id: 'i-09',
		label: 'Klinički centar Srbije',
	},
];

export const DOCUMENTATION_TYPE_OPTIONS: DropDownItemInterface[] = [
	{
        id: 'regular_sessions',
        label: 'Regularne sesije',        
    },
    {
        id: 'regional_sessions',
        label: 'Regionalne sesije',
    },
    {
        id: 'conferences',
        label: 'Konferencije',
    },
    {
        id: 'seminars_by_forum',
        label: 'Seminari po forumu',
    },
    {
        id: 'seminars_participation',
        label: 'Učešće na seminarima',
    },
    {
        id: 'education',
        label: 'Edukacija',
    },
    {
        id: 'east',
        label: 'EAST',
    },
    {
        id: 'laws',
        label: 'Pravni akti',
    },
];
