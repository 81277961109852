import { isDisabled } from '@testing-library/user-event/dist/utils';
import { forwardRef, ButtonHTMLAttributes } from 'react';
import Icon from '../Icon';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'primary' | 'secondary' | 'back' | 'danger';
	wide?: boolean;
	ariaLabel?: string;
	isDisabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			variant = 'primary',
			wide = false,
			className,
			ariaLabel,
			children,
			isDisabled,
			...rest
		},
		ref
	) => {
		let buttonClassNameList = [
			// Base styles
			variant !== "back" 
			  ? "px-8 py-2 rounded-lg font-bold border outline-none focus:outline-none" 
			  : "font-bold",
		  
			// Conditional styles based on disabled state
			isDisabled
			  ? 'cursor-default bg-gray-300 text-gray-500 border-gray-300' // Disabled styles
			  : (() => {
				  if (variant === 'primary') {
					return 'bg-primary-300 text-white border-primary-300 hover:bg-primary group-focus:bg-primary focus:bg-primary';
				  } else if (variant === 'back') {
					return 'flex items-center justify-center gap-2';
				  } else if (variant === 'danger') {
					return 'bg-secondary-500 text-white border-secondary-500 hover:bg-red-700 group-focus:bg-primary focus:bg-primary';
				  } else {
					// Default to 'secondary' style
					return 'bg-white text-primary-300 border-primary-300 hover:bg-primary hover:text-white group-focus:bg-primary focus:bg-primary focus:text-white';
				  }
				})(),
		  ];
		  

		if (className) {
			buttonClassNameList.push(className);
		}

		if (wide) {
			buttonClassNameList.push('w-full');
		}

		const buttonClassName = buttonClassNameList.join(' ');

		return (
			<button
				ref={ref}
				className={buttonClassName}
				aria-label={ariaLabel}
				{...rest}>
				{variant === "back" && <Icon name="arrowLeft" />}
				{children}
			</button>
		);
	}
);

export default Button;
