import { Link } from 'react-router-dom';

import Icon from '../Icon';

import { TableCellInterface } from '../../../types';
interface TableCellProps {
	cell: TableCellInterface;
}





const TableCell: React.FC<TableCellProps> = ({ cell }) => {
  const { url, content, type } = cell;
  const renderCellContent = (cell: TableCellInterface) => {
    switch (type) {
      case 'type':
        return (
          <span className="relative group h-full ">
            <Icon name="file" />
            <p className="bg-gray-500 rounded-md px-2 text-white absolute  text-sm hidden group-hover:inline-block">Otvori fajl</p>
          </span>
        );
      case 'doc':
        return (
          <Link to={url || ''} target="_blank" rel="noopener noreferrer">
            <Icon name="file" />
          </Link>
        );
      case 'heading':
        return <span>{content}</span>;
      case 'custom':
        return <div className={`block text-secondary-400`}>{content}</div>;
      default:
        return <span className={`block text-secondary-400`}>{content}</span>;
    }
  };

  return renderCellContent(cell);
};

export default TableCell;
