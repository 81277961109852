import { Link, useNavigate } from 'react-router-dom';
import Meta from '../components/layout/Meta';
import PageLayout from '../layouts/PageLayout';
import EducationDocumentEntryForm from '../modules/educationDocumentEntryForm/UI';
import Button from '../components/shared/Button';
import { BACK_TO_LIST, BACK_TO_NEWS } from '../constants';

function EducationDocumentEntryPage() {
	const navigate = useNavigate()
	const pageTitle = 'Unos dokumenta';
	return (
		<>
			<p
				onClick={() =>navigate(-1)}
					className='mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group'>
							<Button
								type='button'
								variant='back'
								tabIndex={-1}>
							{BACK_TO_LIST}
							</Button>
				</p>
			<Meta title={pageTitle} />
			<PageLayout title={pageTitle}>
				<EducationDocumentEntryForm />
			</PageLayout>
		</>
	);
}

export default EducationDocumentEntryPage;
