import { Link, useNavigate } from 'react-router-dom';
import Meta from '../../components/layout/Meta';
import Button from '../../components/shared/Button';
import { BACK_TO_LIST } from '../../constants';
import PageLayout from '../../layouts/PageLayout';
import OrganizationForm from '../../modules/organizations/UI/OrganizationForm';
import { useParams } from 'react-router-dom';

function OrganizationFormPage() {
	const {slug} = useParams()
	const pageTitle: string = slug ? 'Izmena institucije' : 'Unos institucije';
	const navigate = useNavigate();

	return (
		<>
			 <span
					className='mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group'>
				<Button
					onClick={() => navigate(-1)}
								type='button'
								variant='back'
								tabIndex={-1}>
							{BACK_TO_LIST}
							</Button>
				</span>
			<Meta title={pageTitle} />
			<PageLayout className='max-w-[500px]' title={pageTitle}>
				<OrganizationForm />
			</PageLayout>
		</>
	);
}

export default OrganizationFormPage;
