import { keepPreviousData, useQueries, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { handleErrors } from "../../../utils"
import NewsApi from "./news.api"
import { NewsPaginationInterface } from "./index.types"
import { AxiosResponse } from "axios";
import queryClient from "../../../config/tanstackQueryConfig";
import { ActionResponse } from "../../../types";


export const QUERY_KEYS = {
	// LATEST_NEWS: 'latest-news',
	NEWS: 'news',
	DOCUMENTS: 'documents'
};
const MINUTE: number = 1000 * 60

const api: NewsApi = new NewsApi()

class NewsStore {
  
    useGetNews = (page?: number, region?:string, rowsPerPage?:any) => {
        return  useQuery(getNewsQueryOptions(page, region, rowsPerPage))
    }

    useGetNewsPhoto = async (id: number) => {
        try {
			const res = await api.getNewsPhoto(id);
			const imageBlob = new Blob([res.data], {
				type: res.headers['content-type'],
			});
			const url = URL.createObjectURL(imageBlob);
			return {
				success: true,
				// message: res.data?.message || 'Logo uspešno dodat',
				data: url,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	}  

	addNews = async (data: any) => {
		try {
			const res = await api.addNews(data);
			const resData = res.data;
			queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NEWS] });
			return {
				success: true,
				message: resData?.message || 'News successfully added!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	}

	getSingleNews = async (id: number) => {
		try {
			const res = await api.getSingleNews(id);
			const resData = res.data;
			return {
				success: true,
				// message: resData?.message || 'Organization successfully added!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	};

	useGetDocuments =  (page?: number, type?:string, searchTerm?: string, region?: string, organization?:string, rowsPerPage?: any) => {
		return useQuery(getDocumentsQueryOptions(page, type, searchTerm, region, organization, rowsPerPage))
	}

	getDocument = async (fileId: number, type: string) => {
		try {
			const res: any = await api.getDocument(fileId, type)
			const fileUrl = window.URL.createObjectURL(res.data);
		return fileUrl
		} 		catch (error) {
			console.error("error")
		}
	};

	addDocuments = async (data: any, type:string) => {
		try {
			const res = await api.addDocument(data, type);
			const resData = res.data;
			queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DOCUMENTS] });
			return {
				success: true,
				message: resData?.message || 'Document successfully added!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	}
}

export default NewsStore;

/**
 * Function that returns query options for fetching news data.
 *
 * @returns {UseQueryOptions<NewsPaginationInterface, Error>}
 * Options for the `useQuery` hook to fetch news data.
 */
export const getNewsQueryOptions = (
    page?: number,
	region?: string,
	rowsPerPage?: any
): UseQueryOptions<NewsPaginationInterface, Error> => ({
	queryKey: [QUERY_KEYS.NEWS, {page, region, rowsPerPage}],
	queryFn: async (): Promise<any> => {
	const response: AxiosResponse<any> = await api.getNews(page, region, rowsPerPage);
        return response.data;
    },
	placeholderData: keepPreviousData,
});

export const getDocumentsQueryOptions = (
	page?: number,
	type?:string,
	searchTerm?:string,
	region?: string,
	organization?: string,
	rowsPerPage?: any
): UseQueryOptions<NewsPaginationInterface, Error> => ({
	queryKey: [QUERY_KEYS.DOCUMENTS, {page, type, searchTerm, region, organization, rowsPerPage}],
	queryFn: async (): Promise<any> => {
	const response: AxiosResponse<any> = await api.getDocuments(page, type, searchTerm, region, rowsPerPage);
        return response.data;
    },
	placeholderData: keepPreviousData,
});

// export const getLatestNewsQueryOptions = (): UseQueryOptions<NewsPaginationInterface, Error> => ({
// 	queryKey: [QUERY_KEYS.LATEST_NEWS],
// 	queryFn: async (): Promise<any> => {
//         const response: AxiosResponse<any> = await api.fetchLatestNews( );
//         return response.data;
//     },
// 	placeholderData: keepPreviousData,
// });