import { Link, useNavigate } from 'react-router-dom';
import {
	useForm,
	FormProvider,
	SubmitHandler,
	FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import FileInput from '../../../components/shared/FileInput';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import Button from '../../../components/shared/Button';

/* Fields Validation */
import { CANCEL, REQUIRED_VALIDATION_RULE } from '../../../constants';
import NewsStore from '../../news/services/news.store';
import RegionsStore from '../../regions/services/regions.store';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import {
	extractMultipleErrors,
	formatDateWithMinus,
	hasNestedObjects,
	showToast,
} from '../../../utils';
import TextEditor from '../../../components/shared/TextEditor';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';

const newsStore = new NewsStore();
const regionsStore = new RegionsStore();

const today = new Date();

const NewsForm = () => {
	const navigate = useNavigate();

	const methods = useForm<FieldValues>({
		defaultValues: {
			title: '',
			description: '',
			mainPhoto: [],
			publicationDate: formatDateWithMinus(today),
		},
	});

	const {
		handleSubmit,
		reset,
		setError,
		formState: { isSubmitting },
	} = methods;
	const onSubmit: SubmitHandler<FieldValues> = async (data) => {
		let formData = new FormData();

		Object.keys(data).forEach((key) => {
			formData.append(key, data[key]);
		});

		const handleError = (message: any) => {
			if (hasNestedObjects(message)) {
				const errorMessages = extractMultipleErrors(message);
				for (const key in errorMessages) {
					setError(key as any, {
						type: 'backend',
						message: errorMessages[key],
					});
				}
			} else {
				showToast(message, false, true);
			}
		};

		const response = await newsStore.addNews(formData);
		const { success, message } = response;

		if (success) {
			showToast(message, success);
			navigate('/education/news');
		} else {
			handleError(message);
		}
		reset({}, { keepValues: true });
	};

	return (
		<div className='max-w-[500px]'>
			{isSubmitting && <TransitionLoader message='Kreiranje...' />}

			<FormProvider {...methods}>
				<form
					className='pks-layout-col-md'
					onSubmit={handleSubmit(onSubmit)}>
					<div className='pks-layout-col-md'>
						{/* Region and Doc Type */}
						<div className='pks-layout-col'>
							<Input
								id='title'
								name='title'
								label='Naslov novosti*'
								placeholder='Unesi naslov novosti'
								autoComplete='off'
								validationRules={REQUIRED_VALIDATION_RULE}
							/>
							<Input
								id='headline'
								name='headline'
								label='Podnaslov novosti*'
								placeholder='Unesi podnaslov novosti'
								autoComplete='off'
								validationRules={REQUIRED_VALIDATION_RULE}
							/>
						</div>
						{/* Docs File Input */}
						<div className='pks-layout-col'>
							<FileInput
								id='mainPhoto'
								required
								name='mainPhoto'
								label='Dodaj glavnu sliku:*'
								allowedTypes={[
									'image/jpeg',
									'image/jpg',
									'image/png',
								]}
								maxSizeMB={16}
							/>
							<p className='text-sm'>
								Napomena: Veličina slike ne sme biti veća od 16
								MB, a format mora biti PNG, JPG ili JPEG.
							</p>
						</div>
						{/* Description */}
						<div className='mb-10'>
							<TextEditor
								label='Opis'
								value={methods.watch('description')}
								onChange={(value) =>
									methods.setValue('description', value)
								}
							/>
						</div>
					</div>

					{/* Submit Button and Actions */}
					<div className='w-full inline-flex flex-wrap gap-4'>
						<Button type='submit' className='flex-grow'>
							Unesi novu vesti
						</Button>
						<Link
							to='/education/news'
							className='flex-auto sm:flex-none'>
							<Button type='button' variant='secondary' wide>
								{CANCEL}
							</Button>
						</Link>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

export default NewsForm;
