import { useNavigate, useNavigation, Form } from 'react-router-dom';
import {
	useForm,
	FormProvider,
	SubmitHandler,
	FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import CheckboxInput from '../../../components/shared/CheckboxInput';
import ModalButton from '../../../components/shared/Modal/ModalButton';
import { ADD_TEXT, EDITING_TEXT, SUBMITTING_TEXT } from '../../../constants';
import { showToast } from '../../../utils';
import RolesStore from '../services/roles.store';

import { REQUIRED_VALIDATION_RULE } from '../../../constants';
import { RegionStatusEnum } from '../../../types';

interface FormValuesInterface {
	role: string;
	status: boolean;
}

interface RoleFormProps {
	role?: any;
	onClose: () => void;
}

const rolesStore = new RolesStore();

/**
 * Form for creating or editing a region.
 *
 * @component
 * @param {RoleFormProps} props - The props object for this component.
 * @param {any} [props.role] - The region to edit, if any. If not provided, a new region will be created.
 * @param {() => void} props.onClose - A function to call when the form modal is closed.
 *
 * @returns {React.JSX.Element} The rendered RegionForm component.
 */
const RoleForm = ({ role, onClose }: RoleFormProps): React.JSX.Element => {
	const navigate = useNavigate();
	const navigation = useNavigation();
	const isSubmitting = navigation.state === 'submitting';
	const isEditing = Boolean(role);

	// React Hook Form methods and state initialization
	const methods = useForm<FormValuesInterface>({
		defaultValues: {
			role: role?.name || '',
			status: role?.status === RegionStatusEnum.Active || false,
		},
	});

	/**
	 * Handles form submission.
	 */
	const onSubmit: SubmitHandler<FieldValues> = async (
		data
	): Promise<void> => {
		const { role: name, status } = data as FormValuesInterface;
		const transformedStatus = status
			? RegionStatusEnum.Active
			: RegionStatusEnum.Inactive;

		const { success, message } = role?.id
			? await rolesStore.updateRole({
					id: role.id,
					name,
					status: transformedStatus,
			  })
			: await rolesStore.addRole({
					name,
					status: transformedStatus,
			  });

		showToast(message, success);
		onClose();
		navigate('.', { replace: true });
	};

	return (
		<>
			<h3 className='h2'>{isEditing ? 'Izmeni rolu' : 'Dodaj rolu'}</h3>
			<FormProvider {...methods}>
				<Form
					method='post'
					className='pks-layout-col-md w-full'
					onSubmit={methods.handleSubmit(onSubmit)}>
					<div className='pks-layout-col'>
						{/* Email Input */}
						<Input
							type='text'
							id='role'
							name='role'
							label='Rola *'
							placeholder='Unesi rolu'
							autoFocus
							autoComplete='off'
							validationRules={REQUIRED_VALIDATION_RULE}
						/>
						{/* <CheckboxInput
							id={isEditing ? role?.id || 'status' : 'status'}
							name='status'
							label='Aktivan'
							defaultChecked={
								isEditing
									? role.status === RegionStatusEnum.Active
									: false
							}
						/> */}
					</div>
					{isEditing && (
						<ModalButton
							className='w-full sm:w-7/12'
							type='submit'
							disabled={isSubmitting}>
							{isSubmitting ? SUBMITTING_TEXT : EDITING_TEXT}
						</ModalButton>
					)}
					{!isEditing && (
						<ModalButton
							className='w-full sm:w-7/12'
							type='submit'
							disabled={isSubmitting}>
							{isSubmitting ? SUBMITTING_TEXT : ADD_TEXT}
						</ModalButton>
					)}
				</Form>
			</FormProvider>
		</>
	);
};

export default RoleForm;
