import { Link, useNavigate } from 'react-router-dom';
import Meta from '../components/layout/Meta';
import PageLayout from '../layouts/PageLayout';
import PageTitleWithActions from '../layouts/PageLayout/TitleWithActions';
import EducationReportsApi from '../modules/educationReports/services/index.api';
import News from '../modules/news/UI/index';

import { ViewEnum } from '../types';
import Button from '../components/shared/Button';

const EducationNewsPage = () => {
	const navigate = useNavigate()
	const pageTitle = 'Vesti i korisne informacije';
	return (
		<>
			<Meta title={pageTitle} />
			<PageLayout>
			<PageTitleWithActions title={pageTitle}>
                    <Link
                        to='/education/add-news'
                        replace
                        className='focus:outline-none group'>
                        <Button  tabIndex={-1}>Unesi nove vesti</Button>
                    </Link>
                </PageTitleWithActions>
				<News view={ViewEnum.Grid} />
			</PageLayout>
		</>
	);
};

export default EducationNewsPage;
