// src/layouts/LoginLayout.tsx
import { useEffect } from 'react';
import {
	useNavigate,
	Outlet /* , ScrollRestoration */,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
/* import { ToastContainer } from 'react-toastify'; */
import 'react-toastify/dist/ReactToastify.css';

import PageLoader from '../PageLayout/Loader';
import Logo from '../../components/shared/Logo';
/* import pksLogo from '../../assets/images/logos/logo.png'; */

import { getPageTitle } from '../../utils';
import { HOME_PAGE_LINK } from '../../constants';
import { useAuth } from '../../context/auth-context';

const LoginLayout = () => {
	const navigate = useNavigate();
	const { isAuth, isLoggingOut } = useAuth();
	const shouldNavigate = isAuth && !isLoggingOut;

	// Redirect to home if user authenticated
	useEffect(() => {
		shouldNavigate && navigate('/', { replace: true });
	}, [shouldNavigate, navigate]);

	// Show loader while checking if user authenticated
	if (shouldNavigate) {
		return <PageLoader init />;
	}

	return (
		<>
			<Helmet>
				<title>{getPageTitle('Logovanje')}</title>
			</Helmet>
			<div className='min-h-screen flex items-center justify-center p-4 sm:p-8'>
				<div className='pks-layout-col-xl w-full'>
					<Logo isLink={false} className='m-auto' />
					<div className='min-h-[507px]'>
						<div className='pks-layout-col-xl max-w-[444px] m-auto p-4 bg-primary-50 rounded-lg sm:p-8'>
							<h1 className='h2'>Logovanje</h1>
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginLayout;
