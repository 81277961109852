import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useModal } from '../../../context/modal-context';
import Icon from '../Icon';

export interface ModalProps {
	children?: any;
}

const Modal = () => {
	const { isOpen, closeModal, modalContent, modalTitle } = useModal();

	const ref = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: any) => {
		if (ref.current && !ref.current.contains(event.target)) {
			closeModal();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (isOpen) document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	}, [isOpen]);

	if (!isOpen) return null;

	return (
		isOpen &&
		createPortal(
			// blur background
			<div className='fixed top-0 left-0 w-full h-screen p-4 lg:p-12 bg-modalBackdrop z-[100] flex items-center justify-center'>
				{/* modal window */}
				<div
					ref={ref}
					className='w-full lg:w-1/2 max-h-full h-fit bg-white shadow-md overflow-y-auto rounded-lg z-50'>
					{/* header of modal (title + close icon) */}
					<div className='flex items-center justify-between h-1/12 sticky top-0 left-0 p-4 bg-white'>
						<div className='w-full flex items-center justify-center text-xl uppercase font-thin text-primary-300 sticky top-0 left-0'>
							<p className='border-b-secondary-500 border-b-2'>
								{modalTitle}
							</p>
						</div>
						<span className='size-6' onClick={closeModal}>
							<Icon name='cross' className='cursor-pointer' />
						</span>
					</div>
					{/* content */}
					<div className='max-h-11/12 p-6 overflow-hidden '>
						{modalContent}
					</div>
				</div>
			</div>,
			document.getElementById('modal') as HTMLElement
		)
	);
};

export default Modal;
