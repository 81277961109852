import { useLocation, useNavigate } from 'react-router-dom';
import {
	useForm,
	FormProvider,
	SubmitHandler,
	FieldValues,
} from 'react-hook-form';

import {
	SERBIA_REGIONS_OPTIONS,
	DOCUMENTATION_TYPE_OPTIONS,
	CANCEL,
	NEWS_PAGE_LINK,
	LOADING_TEXT,
} from '../../../constants';

import Input from '../../../components/shared/Input';
import FileInput from '../../../components/shared/FileInput';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import Button from '../../../components/shared/Button';

/* Fields Validation */
import { REQUIRED_VALIDATION_RULE } from '../../../constants';
import NewsStore from '../../news/services/news.store';
import RegionsStore from '../../regions/services/regions.store';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import {
	extractMultipleErrors,
	hasNestedObjects,
	showToast,
} from '../../../utils';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';
import { useState } from 'react';

const newsStore = new NewsStore();
const regionsStore = new RegionsStore();

const EducationDocumentEntryForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const currentType = location.state.currentType;
	const methods = useForm<FieldValues>({
		defaultValues: {
			regionId: '',
			type: '',
			document: [],
			details: '',
		},
	});

	const useRegionsDropdown = (editFromData?: any) => {
		return usePaginatedDropdown({
			fetchData: (page) => regionsStore.useGetRegions(page),
			extractItems: (data) => {
				return (data?.regions || []).map((region: any) => ({
					id: region.id,
					label: region.name,
				}));
			},
		});
	};

	const { finalItems: finalRegions, isLoadingRef: isLoadingRegionsRef } =
		useRegionsDropdown();

	const {
		handleSubmit,
		reset,
		setError,
		formState: { isSubmitting },
	} = methods;
	const onSubmit: SubmitHandler<FieldValues> = async (data) => {
		let formData = new FormData();

		Object.keys(data).forEach((key) => {
			if (!['type', 'regionId'].includes(key)) {
				formData.append(key, data[key]);
			}
		});

		const handleError = (message: any) => {
			if (hasNestedObjects(message)) {
				const errorMessages = extractMultipleErrors(message);
				for (const key in errorMessages) {
					setError(key as any, {
						type: 'backend',
						message: errorMessages[key],
					});
				}
			} else {
				showToast(message, false, true);
			}
		};

		// if (data.documents && data.documents.length > 0) {
		// 	(Array.from(data.documents) as File[]).forEach((file: File) => {
		// 		formData.append(`documents[]`, file);
		// 	});
		// }

		if (data.regionId)
			formData.append('regionIds[]', data.regionId.toString());

		const response = await newsStore.addDocuments(formData, data.type);
		const { success, message } = response;

		if (success) {
			showToast(message, success);
			navigate(-1);
		} else {
			handleError(message);
		}
		reset({}, { keepValues: true });
	};

	function findIndexById(id: any) {
		return DOCUMENTATION_TYPE_OPTIONS.findIndex((item) => item.id === id);
	}

	const typeIndex = findIndexById(currentType);

	return (
		<div className='max-w-[500px] relative'>
			{isSubmitting && <TransitionLoader message='Kreiranje...' />}

			<FormProvider {...methods}>
				<form
					className='pks-layout-col-md'
					onSubmit={handleSubmit(onSubmit)}>
					<div className='pks-layout-col-md'>
						{/* Region and Doc Type */}
						<div className='pks-layout-col'>
							{/* Region DropDown */}
							<DropDownSelect
								id='regionId'
								name='regionId'
								label='Region*'
								placeholder='Izaberi region'
								options={finalRegions}
								validationRules={REQUIRED_VALIDATION_RULE}
								isLoading={isLoadingRegionsRef.current}
							/>

							{/* Doc Type Input */}
							<DropDownSelect
								id='type'
								name='type'
								label='Tip dokumentacije*'
								placeholder='Izaberi tip dokumentacije'
								options={DOCUMENTATION_TYPE_OPTIONS}
								defaultValue={
									DOCUMENTATION_TYPE_OPTIONS[typeIndex]
								}
								validationRules={REQUIRED_VALIDATION_RULE}
							/>
						</div>

						{/* Docs File Input */}
						<div className='pks-layout-col'>
							<FileInput
								id='document'
								name='document'
								required
								label='Dodaj dokument:*'
								maxSizeMB={100}
							/>
							<p className='text-sm'>
								Napomena: Veličina dokumenta ne sme biti veća od
								100MB.
							</p>
						</div>

						{/* Name and Description */}
						<div className='pks-layout-col'>
							{/* Name Input */}
							<Input
								id='title'
								name='title'
								label='Naziv dokumenta*'
								placeholder='Unesi naziv dokumenta'
								autoComplete='off'
								validationRules={REQUIRED_VALIDATION_RULE}
							/>

							{/* Description Input */}
							<Input
								id='details'
								name='details'
								label='Kratak opis*'
								placeholder='Unesi opis'
								autoComplete='off'
								validationRules={REQUIRED_VALIDATION_RULE}
								height='80'
								asTextarea
							/>
						</div>
					</div>
					{/* Submit Button and Actions */}
					<div className='w-full inline-flex flex-wrap gap-4'>
						<Button type='submit' className='flex-grow'>
							{isSubmitting
								? LOADING_TEXT
								: 'Unesi novi dokument'}
						</Button>
						<span className='flex-auto sm:flex-none'>
							<Button
								onClick={() => navigate(-1)}
								type='button'
								variant='secondary'
								wide>
								{CANCEL}
							</Button>
						</span>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

export default EducationDocumentEntryForm;
