import { useNavigate, useSearchParams } from 'react-router-dom';

import NewsList from './List';
import SeeMoreLink from '../../../components/shared/SeeMoreLink';
import Pagination from '../../../components/shared/Pagination';

// import { useFetchNews } from '../services/index.queries';
import { URL_PARAMS } from '../services/news.api';
import { ViewType, ViewEnum } from '../../../types';
import NewsStore from '../services/news.store';
import Button from '../../../components/shared/Button';

const NEWS = URL_PARAMS.NEWS;


/**
 * NewsPreviewProps Interface
 *
 * Defines the props for the News component.
 * @interface
 * @property {string} [title] - The title of the news section.
 * @property {ViewType} [view] - The view type for displaying the news, either compact, detailed, or grid.
 */
interface NewsPreviewProps {
	title?: string;
	view?: ViewType;
}

/**
 * News Component
 *
 * This component fetches and displays a list of news articles with pagination.
 * It supports different view types (compact, detailed, grid) and provides navigation controls.
 * Loaded via a 'loader' in the router configuration.
 *
 * @param {NewsPreviewProps} props - The props object for the component.
 * @returns {React.JSX.Element | null} The rendered News component or null if data is not available.
 */

const newsStore = new NewsStore()

const News: React.FC<NewsPreviewProps> = ({
	title,
	view = ViewEnum.Compact,
}: NewsPreviewProps): React.JSX.Element | null => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	// Extract query parameters for pagination
	const page = Number(searchParams.get('page')) || NEWS.page;
	const region = searchParams.get("regionIds[]") || ''
	const rowsPerPage = Number(searchParams.get('rowsPerPage')) || NEWS.limit;

	// Fetch news data using the custom useFetchNews hook
	const { data: newsQueryData, isLoading, isError, error } = newsStore.useGetNews(page, region, rowsPerPage);
	


    //  newsStore.useGetNewsPhoto();
	
	const articles = newsQueryData?._embedded.news  || []
	const currentPage = newsQueryData?._embedded?._page || 1;
	const totalPages = newsQueryData?._page_count || 1;


 	 if (!newsQueryData) return null;    
	// Handle page change for pagination
	 const handlePageChange = (newPage: number) => {
		 navigate(`?page=${newPage}${`&rowsPerPage=${rowsPerPage}`}`);
		 if (newPage.toString() !== currentPage) {
			searchParams.set('page', newPage.toString()); 
				
			if (region) {
				searchParams.set('regionIds[]', region )
			}
			
			
			setSearchParams(searchParams);
		}
	 };
	
	
	
	return (
		<aside className='pks-layout-col-md'>
			{title && <h2>{title} </h2>}
			<div className='pks-layout-col-md'>
				<NewsList news={articles} view={view} />
				{/* <SeeMoreLink url='/' label='Sve vesti' /> */}
				<Pagination
					currentPage={page}
					totalPages={totalPages}
					onPageChange={handlePageChange}
				/>
			</div>
		</aside>
	);
};

export default News;