import NewsPreview from '../../modules/news/UI/Preview';
import StatisticsList from '../../modules/statistics/UI/List';
import { ViewEnum } from '../../types';

const fakeStatisticsData = [
	{
		id: '1',
		period: 'u 24 sata',
		value: 23,
		description: 'Increase in user activity',
	  },
	  {
		id: '2',
		period: 'u 7 dana',
		value: 44,
		description: 'Drop in fraud cases reported',
	  },
	  {
		id: '3',
		period: 'Ukupno',
		value: 1825,
		description: 'Rise in new account registrations',
	},
	  
  ];
  

const PreviewNewsStatisticsSidebar = () => {
	return (
		<div className='pks-layout-col-xl'>
			<NewsPreview view={ViewEnum.Detailed} />
			<StatisticsList data={fakeStatisticsData} />

		</div>
	);
};

export default PreviewNewsStatisticsSidebar;
