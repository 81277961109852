import { LoaderFunctionArgs, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Pagination from '../../../components/shared/Pagination';
import ForumMembersList from './ForumMembersList';

import ForumMembersStore from '../services/forumMembers.store';
import { useEffect, useState } from 'react';
import SearchInput from '../../../components/shared/SearchInput';
import DropDownSelect from '../../../components/shared/DropdownSelect';
import RegionsStore from '../../regions/services/regions.store';
import { DropDownItemInterface } from '../../../types';
import OrganizationsStore from '../../organizations/services/organizations.store';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';

const forumMembersStore = new ForumMembersStore();
const regionsStore = new RegionsStore()
const organizationsStore = new OrganizationsStore()

const ForumMembers = () => {
	
	const [searchParams, setSearchParams] = useSearchParams();

	//Parse URL search parameters 
	const page = Number(searchParams.get('page')) || 1;
	const region = searchParams.get("regionIds[]") || ''
	const organization = searchParams.get('organizationIds[]') || ''
	
	//Search field search param
	const [searchValue, setSearchValue] = useState(
		searchParams.get('name') || ''
	);

	// Parse URL search parameters forum members
	const { data: forumMembersQueryData, isFetching } = forumMembersStore.useGetForumMembers(page, searchValue, region, organization);
	const forumMembers = forumMembersQueryData?._embedded?.users || [];
	const currentPage = forumMembersQueryData?._embedded?._page || 1;
	const totalPages = forumMembersQueryData?._page_count || 1;

	// ORGANIZATIONS
	const useOrganizationsDropdown = () => {
		return usePaginatedDropdown({
		  fetchData:  (page) =>  organizationsStore.useGetOrganizations(page, ''),
		  extractItems: (data) => {
			return (data?.organizations || []).map((org: any) => ({
			  id: org.id.toString(),
			  label: org.name,
			}));
		  },
		});
	};
	const { finalItems: finalOrganizations, isLoadingRef: isLoadingOrganizations } = useOrganizationsDropdown();


	const organizationsOptions: DropDownItemInterface[] = [
		{
		  id: 'all',
		  label: 'Sve institucije',
		},
		...finalOrganizations,
		];

	let selectedOrganizationIndex = organizationsOptions.findIndex((option) => option.id === organization)
	

	

	// REGIONS
	const useRegionsDropdown =  () => {
		return usePaginatedDropdown({
			fetchData: (page) => regionsStore.useGetRegions(page, ''),
			extractItems: (data) => {
			  return  (data?.regions || []).map((region: any) => ({
				id: region.id.toString(),
				label: region.name,
			  }));
			},
		  });
	  };
	  const { finalItems: finalRegions, isLoadingRef: isLoadingRegions } = useRegionsDropdown();
	  const regionOptions: DropDownItemInterface[] = [
		{
		  id: 'all',
		  label: 'Svi regioni',
		},
		...finalRegions,
	  ];
	  const selectedRegionIndex = regionOptions.findIndex((option) => 
		option.id === region
	)
	
	/**
	 * Handles page changes in pagination.
	 * Updates the URL with the new page number.
	 *
	 * @param {number} newPage - The new page number to navigate to.
	 */
	const handlePageChange = (newPage: number ) => {
		if (newPage.toString() !== currentPage) {
			searchParams.set('page', newPage.toString()); 
				
			if (region) {
				searchParams.set('regionIds[]', region )
			}
			if (organization) {
				searchParams.set('organizationIds[]', organization)
			}
			
			setSearchParams(searchParams);
		}
	};

		const handleSearch = (debouncedValue: string): void => {
			setSearchValue(debouncedValue);
			searchParams.set('name', debouncedValue);
		setSearchParams(searchParams)
	};

	const handleFilterChange = (selectedFilter: string, paramName: string) => {
		searchParams.set('page', '1');
		if (selectedFilter === 'all') {
		searchParams.delete(paramName);
		} else {
		searchParams.set(paramName, selectedFilter);
		}
		setSearchParams(searchParams);
	};

	const handleRegionChange = (selectedRegion: string) => {
		handleFilterChange(selectedRegion, 'regionIds[]');
	};

	const handleOrganizationChange = (selectedOrganization: string) => {
		handleFilterChange(selectedOrganization, 'organizationIds[]');
	};

	const handleRegionDelete = () => {
		searchParams.delete('regionIds[]');
		searchParams.set('page', '1');
		setSearchParams(searchParams); 
	  };
	const handleOrganizationDelete = () => {
		searchParams.delete('organizationIds[]'); 
		searchParams.set('page', '1'); 
		setSearchParams(searchParams); 
	  };


	return (
		<div className='pks-layout-col-md '>
			
			<div className=' flex lg:flex-row flex-col items-center lg:gap-8 gap-1 mb-2 w-12/12'>
			<span className='lg:w-4/12 w-full '>
				<DropDownSelect
						label='Izaberi region'
						options={regionOptions}
						isLoading={isLoadingRegions.current}
						onRemoveFilter={handleRegionDelete}
						onChange={handleRegionChange}
						selectedOptionIndex={
							selectedRegionIndex > -1 ? selectedRegionIndex : 0
						}
						/>
				</span>
				<span className='lg:w-4/12 w-full'>
				<DropDownSelect
						label='Izaberi instituciju'
						options={organizationsOptions}
						isLoading={isLoadingOrganizations.current}
					onRemoveFilter={handleOrganizationDelete}

						onChange={handleOrganizationChange}
						selectedOptionIndex={
							selectedOrganizationIndex > -1 ? selectedOrganizationIndex : 0
						}
					/>
				</span>
			</div>
			<SearchInput
				value={searchValue}
				onSearch={handleSearch}
				autofocus
				isFetching={isFetching}
			/>
			<ForumMembersList forumMembers={forumMembers} />
			<Pagination
				currentPage={page}
				totalPages={totalPages}
				onPageChange={handlePageChange}
			/>
		</div>
	);
};

export default ForumMembers;

/**
 * Loader function to prefetch forum members.
 */
export async function loader({ request }: LoaderFunctionArgs) {
	const { searchParams } = new URL(request.url);
	const page = Number(searchParams.get('page')) || 1;
	const name = searchParams.get("name") || ""
	const region = searchParams.get('regionIds[]') || "";
	const organization = searchParams.get('organizationIds[]') || "";

	// Fetch data based on the page number
	await forumMembersStore.preloadForumMembers(page, name, region, organization);
	return null;
}
