import { ROUTES } from "../../../constants";
import { MenuDataProps } from "../../../types";

export const menuData: MenuDataProps[] = [
  {
    label: 'Administracija',
    pathName: ROUTES.DASHBOARD.ADMINISTRATION.ROOT.absPath,
    children: [
      {
        heading: 'Forum',
        submenu: [
          {
            label: 'Članovi foruma',
            href: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.ROOT.absPath,
          },
          // {
          //   label: 'Dodaj člana',
          //   href: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.FORUM_MEMBER_ADD.absPath,
          // },
          // {
          //   label: 'Izmeni člana',
          //   href: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.FORUM_MEMBER_EDIT.absPath,
          // },
        ],
      },
      {
        heading: 'Institucije',
        submenu: [
          {
            label: 'Institucije',
            href: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS.ROOT.absPath,
          },
          // {
          //   label: 'Dodaj organizaciju',
          //   href: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS.ORGANIZATION_ADD.absPath,
          // },
          // {
          //   label: 'Izmeni organizaciju',
          //   href: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS.ORGANIZATION_EDIT.absPath,
          // },
        ],
      },
      {
        heading: 'Regioni',
        submenu: [
          {
            label: 'Regioni',
            href: ROUTES.DASHBOARD.ADMINISTRATION.REGIONS.ROOT.absPath,
          },
        ],
      },
      {
        heading: 'Role',
        submenu: [
          {
            label: 'Role',
            href: ROUTES.DASHBOARD.ADMINISTRATION.ROLES.ROOT.absPath,
          },
        ],
      },
      // {
      //   heading: 'Forum Old',
      //   submenu: [
      //     {
      //       label: 'Forum',
      //       href: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.ROOT.absPath,
      //     },
      //     {
      //       label: 'Članovi foruma',
      //       href: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_MEMBERS.absPath,
      //     },
      //     {
      //       label: 'Savet foruma',
      //       href: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_COUNCIL.absPath,
      //     },
      //     {
      //       label: 'Izveštaji o radu foruma',
      //       href: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_REPORTS.absPath,
      //     },
      //   ],
      // },
    ],
  },
  {
    label: 'Slučajevi prevare',
    href: ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath,
    // children: [
    // {
    //   heading: 'Fraud Cases',
    //   submenu: [
    //     {
    //       label: 'Pregled Fraud Cases',
    //       href: ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath,
    //     },
    //     // {
    //     //   label: 'Dodaj Fraud Case',
    //     //   href: ROUTES.DASHBOARD.FRAUD_CASES.FRAUD_CASE_ADD.absPath,
    //     // },
    //     // {
    //     //   label: 'Detalji Fraud Case-a',
    //     //   href: ROUTES.DASHBOARD.FRAUD_CASES.FRAUD_CASE_DETAILS.absPath,
    //     // },
    //   ],
    // },
    // ],
  },
  {
    label: 'Edukacija i korisne informacije',
    pathName: ROUTES.DASHBOARD.EDUCATION.ROOT.absPath,
    children: [
      {
        heading: 'Konferencije',
        submenu: [
          {
            label: 'Redovne sednice Foruma',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/regular_sessions`,
          },
          {
            label: 'Regionalne sednice foruma',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/regional_sessions`,
          },
          {
            label: 'Konferencije sa učešćem Foruma',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/conferences`,
          },
        ],
      },
      {
        heading: 'Seminari',
        submenu: [
          {
            label: 'Seminari u organizaciji Foruma',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/seminars_by_forum`,
          },
          {
            label: 'Seminari sa učešćem Foruma',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/seminars_participation`,
          },
          {
            label: 'Edukacije u organizaciji Foruma',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/education`,
          },
        ],
      },
      {
        heading: '',
        submenu: [
          {
            label: 'EAST',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/east`,
          },
          {
            label: 'Zakoni i propisi',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/laws`,
          },
          {
            label: 'Vesti i korisne informacije',
            href: ROUTES.DASHBOARD.EDUCATION.NEWS.absPath,
          },
        ],
      },
    ],
  },
];
