/**
 * Converts a value to an ISO string. Handles Date objects, date-like strings, and timestamps.
 * @param {Date|string|number} value - The value to convert (Date object, date-like string, or timestamp).
 * @returns {string} - The ISO string representation of the date.
 * @throws {TypeError} - If the input cannot be converted to a Date object.
 */
export function dateToISO(value: string | number | Date): string {
	let date;

	// Check if the input is already a Date object
	if (value instanceof Date) {
		date = value;
	}
	// Check if the input is a date-like string or a timestamp
	else if (typeof value === 'string' || typeof value === 'number') {
		date = new Date(value);
	}
	// Handle invalid input
	else {
		throw new TypeError(
			'Expected input to be a Date object, date-like string, or timestamp.'
		);
	}

	// Ensure that the date is valid
	if (isNaN(date.getTime())) {
		throw new Error('Invalid date value.');
	}

	return date.toISOString();
}

/**
 * Converts an ISO string to a Date object. Throws an error if the ISO string is invalid.
 * @param {string} isoString - The ISO string to convert.
 * @returns {Date} - The Date object representation of the ISO string.
 * @throws {Error} - If the ISO string is invalid.
 */
function parseDate(isoString: string): Date {
	const date = new Date(isoString);
	if (isNaN(date.getTime())) {
		throw new Error('Invalid date value.');
	}
	return date;
}

/**
 * Formats an ISO date string into a human-readable format.
 *
 * This function converts an ISO 8601 string into a date format commonly used in Europe,
 * with slashes ("/") separating day, month, and year, and optionally includes time.
 *
 * @param {string} isoString - The ISO date string to be formatted.
 * @param {boolean} [includeTime=true] - Whether to include time in the output. If false, only date is returned. Defaults to true.
 * @returns {string} The formatted date string in "DD/MM/YYYY" or "DD/MM/YYYY HH:mm:ss" format.
 * @throws {Error} - If the ISO string is invalid.
 */
export const formatDate = (
	isoString: string,
	includeTime: boolean = true
): string => {
	try {
		const date = parseDate(isoString);

		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
		const year = date.getFullYear();

		if (includeTime) {
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');

			return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
		} else {
			return `${day}/${month}/${year}`;
		}
	} catch (error) {
		console.error('Error formatting date:', error);
		return '';
	}
};

export const formatToISOTYmd = (
	dateInput: string,
	formatString: string = 'DD/MM/YYYY'
): string => {
	const formatParts = formatString.split(/[^A-Za-z]+/); // Split format by non-alphabetic characters
	const inputParts = dateInput.split(/[^0-9]+/); // Split input by non-numeric characters

	let day = '';
	let month = '';
	let year = '';

	formatParts.forEach((part, index) => {
		if (part === 'DD') {
			day = inputParts[index];
		} else if (part === 'MM') {
			month = inputParts[index];
		} else if (part === 'YYYY') {
			year = inputParts[index];
		}
	});

	// Validate date components
	if (!day || !month || !year) {
		return ''; // Return an empty string if any component is missing
	}

	// Create a Date object to validate the parsed date
	const date = new Date(`${year}-${month}-${day}`);
	if (isNaN(date.getTime())) {
		return ''; // Return an empty string for invalid dates
	}

	// Format the date to YYYY-MM-DD
	return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const formatDateWithPoints = (dateString: string) => {
	if (!dateString || isNaN(Date.parse(dateString))) {
		return 'nedostupno';
	}

	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const year = date.getFullYear();

	return `${day}.${month}.${year}`;
};

export const formatDateWithMinus = (dateString: Date) => {
	if (!dateString ) {
		return 'nedostupno';
	}
	const day = String(dateString.getDate()).padStart(2, '0');
	const month = String(dateString.getMonth() + 1).padStart(2, '0');
	const year = dateString.getFullYear(); // Jahr
  
	return `${year}-${month}-${day}`;
};