import { useNavigate, useParams } from 'react-router-dom';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import ForumMemberForm from '../../modules/forumMembers/UI/ForumMembersForm';
import Button from '../../components/shared/Button';
import { BACK_TO_LIST } from '../../constants';
import { Link } from 'react-router-dom';

const ForumMemberFormPage = () => {
	const { slug } = useParams();
	const pageTitle = slug ? 'Izmeni člana' : 'Dodaj člana';
	const navigate = useNavigate()
  
  return (
		<>
			<span
					className='mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group'>
							<Button
              onClick={() => navigate(-1)}
                type='button'
								variant='back'
								tabIndex={-1}>
							{BACK_TO_LIST}
							</Button>
				</span>
			<Meta title={pageTitle} />
			<PageLayout className='max-w-[500px]' title={pageTitle}>
				<ForumMemberForm />
			</PageLayout>
		</>
	);
};

export default ForumMemberFormPage;
