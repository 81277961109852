import { useEffect, useRef, useState } from 'react';

import Modal, { ModalHandleInterface } from '../../components/shared/Modal';
import ModalButton from '../../components/shared/Modal/ModalButton';

import { useAuth } from '../../context/auth-context';
import { showSessionExpiredToast } from '../../utils';

const InactivityModal = () => {
	const {
		showInactivityModal,
		setShowInactivityModal,
		setIsInactivityModalButtonClicked,
		logoutAuthUser,
	} = useAuth();

	const dialogRef = useRef<ModalHandleInterface>(null);
	const timeoutIdRef = useRef<NodeJS.Timeout | null>(null); // Use ref to track the timeout ID
	const [isAnimating, setIsAnimating] = useState(false); // Track animation state

	useEffect(() => {
		// Open the modal when showInactivityModal becomes true
		if (showInactivityModal) {
			dialogRef?.current?.open();
			setIsAnimating(true); // Start animation when modal is open
		}
	}, [showInactivityModal]);

	useEffect(() => {
		// Only set the timeout if the modal is open
		if (showInactivityModal) {
			// Logout the user after 60 seconds
			timeoutIdRef.current = setTimeout(() => {
				logoutAuthUser();
				showSessionExpiredToast();
			}, 60000); // 1 minute timer

			// Cleanup timeout on modal close or unmount
			return () => {
				if (timeoutIdRef.current) {
					clearTimeout(timeoutIdRef.current);
					timeoutIdRef.current = null;
				}
			};
		} else {
			// Cleanup on modal close
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
				timeoutIdRef.current = null;
			}
		}
	}, [showInactivityModal, logoutAuthUser]);

	const handleClick = () => {
		// Close the modal and reset the inactivity timeout
		setShowInactivityModal(false);
		setIsInactivityModalButtonClicked(true);
		setIsAnimating(false); // Stop animation when user confirms

		// Clear the timeout manually when the user confirms
		if (timeoutIdRef.current) {
			clearTimeout(timeoutIdRef.current);
			timeoutIdRef.current = null;
		}
	};

	return (
		<>
			{showInactivityModal && (
				<Modal
					ref={dialogRef}
					onClose={handleClick}
					hasActionButton={false}
					closeButtonText='POTVRDI'>
					{/* Blue line showing the time remaining */}
					<div
						className={`h-1 bg-primary-300 transition-all duration-60000  absolute top-0 left-0 right-0 ${
							isAnimating ? 'animate-width' : ''
						}`}
					/>
					<div>
						<p className='text-center'>
							Ukoliko želite da ostanete ulogovani, kliknite na
							dugme za potvrdu.
						</p>
					</div>
					{/* <ModalButton
						onClick={handleClick}
						className='w-full sm:w-7/12'>
						Potvrdi
					</ModalButton> */}
				</Modal>
			)}
		</>
	);
};

export default InactivityModal;
