import { kMaxLength } from 'buffer';
import { EMAIL_REGEX, PASSWORD_REGEX } from './regex';

const REQUIRED_TEXT = 'Vrednost je obavezna i ne može biti prazna.';

export const REQUIRED_VALIDATION_RULE = {
	required: REQUIRED_TEXT,
};

export const NUMBERS_VALIDATION_RULES = {
	pattern: {
		value: /^[0-9]+$/,
		message: 'Vrednost može sadržati samo brojeve',
	},
};

export const LETTERS_VALIDATION_RULES = {
	pattern: {
		value: /^([A-Za-zČĆŽŠĐLjNjDžčćžšđljnjdžЉЊЏЂЖША-Яа-яёЁ0-9 ]*)$/,
		message: 'Enter letters, dashes, and spaces.',
	},
};

export const TITLE_VALIDATION_RULE = {
	...REQUIRED_VALIDATION_RULE,
	pattern: {
		value: /^[A-Za-zА-Яа-яЂђЖжЉљЊњЏџЧчЋћШшĐđŽžČčĆćŠš0-9\s-]*$/,
		message:
			'Vrednost može sadržati samo slova, brojeve, crtice ili razmake.',
	},
};

export const LETTERS_AND_NUMBERS_VALIDATION_RULES = {
	pattern: {
		value: /^[A-Za-zА-Яа-яЂђЖжЉљЊњЏџЧчЋћШшĐđŽžČčĆćŠš0-9]*$/,
		message: 'Vrednost mogu biti samo slova i brojevi',
	},
};

export const EMAIL_VALIDATION_RULES = {
	...REQUIRED_VALIDATION_RULE,
	pattern: {
		value: EMAIL_REGEX,
		message: 'E-mail adresa nije validna. example@email.com',
	},
};

export const PASSWORD_VALIDATION_RULES = {
	...REQUIRED_VALIDATION_RULE,
	minLength: {
		value: 12,
		message: 'Lozinka mora imati najmanje 12 karaktera',
	},
	maxLength: {
		value: 50,
		message: 'Lozinka ne može biti duža od 50 karaktera',
	},
	pattern: {
		value: PASSWORD_REGEX,
		message:
			'Lozinka mora sadržati barem jedno veliko slovo, jedno malo slovo, jedan broj i jedan specijalni znak',
	},
};

export const BIN_VALIDATION_RULES = {
	...NUMBERS_VALIDATION_RULES,
	maxLength: {
		value: 8,
		message: 'Vrednost može imati najviše 8 cifara',
	},
};

export const ACQUIRER_ID_VALIDATION_RULES = {
	...REQUIRED_VALIDATION_RULE,
	...NUMBERS_VALIDATION_RULES,
	maxLength: {
		value: 6,
		message: 'Vrednost može imati najviše 6 cifara',
	},
};

export const MID_VALIDATION_RULES = {
	...LETTERS_AND_NUMBERS_VALIDATION_RULES,
	maxLength: {
		value: 20,
		message: 'Vrednost može imati najviše 20 cifara',
	},
};

export const TID_VALIDATION_RULES = {
	...LETTERS_AND_NUMBERS_VALIDATION_RULES,
	maxLength: {
		value: 10,
		message: 'Vrednost može imati najviše 10 cifara',
	},
};

export const POSTAL_CODE_VALIDATION_RULES = {
	pattern: {
		value: /^\d{5}(-\d{4})?$/,
		message: 'Enter a valid postal code.',
	},
};

export const DOMAIN_VALIDATION_RULES = {
	...REQUIRED_VALIDATION_RULE,
	pattern: {
		value: /^(https?:\/\/)?([a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,})$/,
		message: 'Enter a valid domain.',
	},
};

export const PHONE_VALIDATION_RULES = {
	...REQUIRED_VALIDATION_RULE,
	pattern: {
		value: /^(\+?\d{8,15}|(0|00)?\d{8,15})$/,
		message: 'Enter a valid phone number',
	},
};

export const MOBILE_PHONE_VALIDATION_RULES = {
	...REQUIRED_VALIDATION_RULE,
	pattern: {
		value: /^\+?\d{8,15}$/,
		message: 'Enter a valid mobile phone number',
	},
};

export const FAX_VALIDATION_RULES = {
	...REQUIRED_VALIDATION_RULE,
	pattern: {
		value: /^[0-9+()-]{7,20}$/,
		message: 'Enter a valid fax number.',
	},
};
