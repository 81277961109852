import http from '../../../utils/http';

//!!! ADD TYPES

class FraudCasesApi {
  // Get Fraud Cases
  async getFraudCases(page?: number, category?: string, organization?: any) {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', page.toString());
    }

    if (category) {
      params.append('typeId', category);
    }

    if (organization) {
      params.append('organizationIds[]', organization);
    }

    // Construct the full URL with the query string
    const url = `/fraudcases${params.toString() ? `?${params.toString()}` : ''}`;

    return http.get(url);
  }

  // Get Fraud Case
  async getFraudCase(id?: number) {
    return http.get(`/fraudcases/${id}`);
  }

  // Add Fraud Case
  async addFraudCase(data: any) {
    return http.post(`/fraudcases`, data);
  }

  // Add Fraud Case
  async editFraudCase(id: number, data: any) {
    return http.post(`/fraudcases/${id}`, data);
  }

  // Get Fraud Case Comments
  async getFraudCaseComments(id?: number, page?: number) {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', page.toString());
    }

    const url = `/fraudcases/${id}/comments${params.toString() ? `?${params.toString()}` : ''}`;

    return http.get(url);
  }

  // Add Fraud Case Comment
  async addFraudCaseComment(id: number, data: any) {
    return http.post(`/fraudcases/${id}/comments`, data);
  }

  // Delete Fraud Case Comment
  async deleteFraudCaseCommentForm(id: number, data: any) {
    return http.delete(`/fraudcases/comments/${id}`, data);
  }

  // Get Fraud Case Files
  async getFraudCaseFiles(id: number) {
    return http.get(`/fraudcases/${id}/files`);
  }

  // Get Fraud Case Files
  async getFraudCaseFile(fileId: number) {
    return http.get(`/fraudcases/files/${fileId}`, { responseType: 'blob' });
  }

  //  Delete Fraud Case File
  async deleteFraudCaseFile(fileId: number) {
    return http.delete(`/fraudcases/files/${fileId}`);
  }

  // Add Fraud Case Files
  async addFraudCaseFiles(id: number, data: any) {
    return http.post(`/fraudcases/${id}/files`, data);
  }

  // Get Fraud Cases Types
  async getFraudCasesTypes() {
    return http.get('/fraudcases/types');
  }

  // Get Fraud Cases Card Types
  async getFraudCasesCardTypes() {
    return http.get('/fraudcases/cardtypes');
  }

  // Get Fraud Cases Terminal Types
  async getFraudCasesTerminalTypes() {
    return http.get('/fraudcases/terminaltypes');
  }
}

export default FraudCasesApi;
