
import http from '../../../utils/http'

const API_KEY = "680cb578f86d45569b4b52cb42cc3531";

const URL_PARAMS = {
	LATEST_NEWS: {
		limit: 4,
	},
	NEWS: {
		page: 1,
		limit: 5,
		showLimitInUrl: false,
	},
};

class NewsApi {
	// Fetch latest news
	getNews = async (page?: number, region?: string, rowsPerPage?: any) => {
		const params = new URLSearchParams();
		
		if (page) {
			params.append('page', page.toString());
		}

		if (region) {
			params.append('regionIds[]', region)
		}

		if (rowsPerPage) {
			params.append('rowsPerPage', rowsPerPage)
		}

		const url = `/news${params.toString() ? `?${params.toString()}` : ''}`
		
		return http.get(url);
	};

	getNewsPhoto =  (id?: number) => {
		return http.get(`/news/${id}/photo`, {responseType: "blob"})
	}

	addNews = (data:any) => {
		return http.post(`/news`, data)
	}

	getSingleNews = (id: number) => {
		return http.get(`/news/${id}`)
	}

	getDocuments = async (page?: number, type?:string, searchTerm?: string, region?: string, organization?:string, rowsPerPage?: any) => {
		const params = new URLSearchParams();
		
		if (page) {
			params.append('page', page.toString());
		}
		if (searchTerm) {
			params.append('title', searchTerm);
		}
		if (region) {
			params.append('regionIds[]', region)
		}		
		if (organization) {
			params.append('organizationIds[]', organization)
		}
		if (rowsPerPage) {
			params.append('rowsPerPage', rowsPerPage)
		}

		const url = `/documents/${type}${params.toString() ? `?${params.toString()}` : ''}`
		
		return http.get(url);
	};

	getDocument = async (fileId: number, type: string) => {
		return http.get(`/documents/${type}/${fileId}/file`, { responseType: 'blob' });
	}

	addDocument(data: any, type: string) {
		
		return http.post(`/documents/${type}`, data);
	}
}

export { URL_PARAMS};
export default NewsApi;