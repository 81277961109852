import Icon from '../../shared/Icon';
import MegaMenu from './MegaMenu';
import { useRef, useState } from 'react';
import { MobileNavigationDrawerProps } from '../../../types';
import { useLogout } from '../../../hooks/useLogout';

const MobileNavigationDrawer = ({ isDrawerOpen, setIsDrawerOpen, drawerButtonRef }: MobileNavigationDrawerProps) => {
  const { handleLogout } = useLogout();

  const [clicked, setClicked] = useState<number | null>(null);
  const [clickedSubMenu, setClickedSubMenu] = useState<null | number>(null);

  const drawerRef = useRef<HTMLDivElement>(null);

  const handleToggle = (index: any) => {
    setClickedSubMenu(null);
    if (clicked === index) {
      return setClicked(null);
    } else {
      setClicked(index);
    }
  };

  const handleToggleSubmenu = (index: null | number) => {
    if (clickedSubMenu === index) {
      return setClickedSubMenu(null);
    } else {
      setClickedSubMenu(index);
    }
  };

  return (
    <div className="mobile_navigation fixed top-0 right-0 z-50  -translate-x-100" ref={drawerRef} tabIndex={isDrawerOpen ? 0 : -1}>
      <div className="">
        {isDrawerOpen && (
          <div className="backdrop  fixed inset-0 backdrop-blur-md backdrop-brightness-50  h-screen transition-all duration-75  w-full flex justify-end   ">
            <div
              onClick={() => {
                setIsDrawerOpen(false);
                setClicked(null);
                setClickedSubMenu(null);
              }}
              className="close_drawer  size-[44px]  bg-black flex items-center justify-center cursor-pointer  "
            >
              <button className="bg-black z-50 w-full text-white flex items-center justify-center ">
                <Icon name="cross" className="text-white size-5" />
              </button>
            </div>
          </div>
        )}

        <div
          className={`drawer_content bg-white fixed top-0 left-0  w-[calc(100%-44px)] max-w-[100%] h-screen z-50 overflow-y-auto transition-all duration-75  ${
            isDrawerOpen ? 'translate-x-0' : '-translate-x-full'
          } `}
        >
          <div className="flex flex-col h-screen justify-between">
            <div className=" ">
              <MegaMenu handleToggle={handleToggle} handleToggleSubmenu={handleToggleSubmenu} clicked={clicked} clickedSubMenu={clickedSubMenu} setIsDrawerOpen={setIsDrawerOpen} />
            </div>
            <div className="pl-4 pb-8 flex justify-end lg:hidden  flex-col flex-0 h-fit">
              <span onClick={handleLogout} className="flex items-center gap-1">
                <Icon name="logOut" />
                Izloguj se
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavigationDrawer;
