import Statistics from '../../modules/statistics/UI';
import FreudCasesPreview from '../../modules/fraudCasesOld/UI/Preview';
import StatisticsList from '../../modules/statistics/UI/List';

const fakeStatisticsData = [
	{
		id: '1',
		period: 'u 24 sata',
		value: 23,
		description: 'Increase in user activity',
	  },
	  {
		id: '2',
		period: 'u 7 dana',
		value: 44,
		description: 'Drop in fraud cases reported',
	  },
	  {
		id: '3',
		period: 'Ukupno',
		value: 1825,
		description: 'Rise in new account registrations',
	},
	  
  ];

const PreviewWithStatisticsSidebar = () => {
	return (
		<div className='pks-layout-col-xl'>
			{/* <Statistics sidebar /> */}
			<StatisticsList data={fakeStatisticsData} />
			<FreudCasesPreview />
		</div>
	);
};

export default PreviewWithStatisticsSidebar;
