import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import Modal from '../components/shared/ModalNew';
import { useAuth } from './auth-context';

interface ModalContextProps {
    isOpen: boolean;
    openModal: (modalContent: JSX.Element, modalTitle?:string) => void;
    closeModal: () => void;
    modalContent: JSX.Element | null
    modalTitle?:string
}

interface ModalProviderProps {
    children: ReactNode;
}


const ModalContext = createContext<ModalContextProps | undefined>(undefined)

export const useModal = () => {
    const context = useContext(ModalContext)
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
		const { isAuth } = useAuth();
    const [modalTitle, setModalTitle] = useState<string>("")
    const [modalContent, setModalContent] = useState<JSX.Element | null>(null)
    const [isOpen, setIsOpen] = useState(false);
    const openModal = (modalContent: JSX.Element, modalTitle?:string) => {
        setModalContent(modalContent)
        setIsOpen(true)
       if(modalTitle) setModalTitle(modalTitle)
    };
    
    const closeModal = () => {
        setModalContent(null)
        setIsOpen(false)
    };

    useEffect(() => {
        if(!isAuth && isOpen) closeModal()
    }, [isAuth])

    return (
        <ModalContext.Provider value={{ isOpen, openModal, closeModal, modalContent, modalTitle }}>
            {children}
            {isOpen && <Modal/>}
        </ModalContext.Provider>
      );
}