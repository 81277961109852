export const WEBSITE_NAME = 'Privredna komora Srbije';
export const LOADING_TEXT = 'Učitavanje...';
export const SUBMITTING_TEXT = 'Slanje...';
export const EDITING_TEXT = 'Izmeni'
export const ADD_TEXT = 'Dodaj'
export const ADD_ORGANIZATION = "Dodaj instituciju"
export const EDIT_ORGANIZATION = "Izmeni instituciju"
export const CANCEL = "Odustani"
export const CLOSE = "Zatvori"
export const SEND = "Pošalji"
export const BACK_TO_LIST = "Nazad na listu"
export const BACK_TO_NEWS = "Nazad na vesti"