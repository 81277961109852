import { Navigate } from 'react-router-dom';
import Meta from '../components/layout/Meta';
import PageLayout from '../layouts/PageLayout';
import Statistics from '../modules/statistics/UI';
import FraudCasesPreview from '../modules/fraudCases/UI/FraudCasesPreview';
import { ViewEnum } from '../types';
import News from '../modules/news/UI';
import NewsPreview from '../modules/news/UI/Preview';
import FraudCasesList from '../modules/fraudCases/UI/FraudCasesList';

/* import PageLayout from '../layouts/PageLayout/';
import Statistics from '../modules/statistics/UI';
import FreudCasesPreview from '../modules/fraudCasesOld/UI/Preview'; */

function HomePage() {
	return (
		<>
			<Meta />
			{/* <Navigate to='/' replace={true}></Navigate> */}

			 <PageLayout title="Poslednji slučajevi" >
				<FraudCasesPreview title="" />				
			</PageLayout> 
		</>
	);
}

export default HomePage;
