import { useState } from "react"
import Button from "../Button"

interface ConfirmDenyInterface{
    infoMessage: string,
    onConfirm: () => void,
    onDeny: () => void,
    closeModal: () => void,
}

const ConfirmDenyDialog = ({ infoMessage, onConfirm, onDeny, closeModal  }: ConfirmDenyInterface) => {

    const [isDisabled, setIsDisabled] = useState(false)
    const handleConfirm = async () => {
        setIsDisabled(true)
        await onConfirm()
        closeModal()
        setIsDisabled(false)
    }

    return (
        <>
            <p className="text-center">{infoMessage}</p>
            <div className=" w-full flex items-center justify-center gap-4 mt-4">
                <Button isDisabled={isDisabled} variant="danger" onClick={handleConfirm}>Potvrdi</Button>
                <Button isDisabled={isDisabled} variant="secondary"  onClick={onDeny}>Odustani</Button>
            </div>
        </>
    )
}

export default ConfirmDenyDialog
