import PageLayout from '../layouts/PageLayout';
import dummyNewsLargeImg from '../assets/images/dummy/newsLarge.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NewsStore from '../modules/news/services/news.store';
import Icon from '../components/shared/Icon';
import { Link } from 'react-router-dom';
import Button from '../components/shared/Button';
import { BACK_TO_NEWS } from '../constants';
import { formatDateWithPoints } from '../utils';

const newsStore = new NewsStore();

interface NewsItem {
  date_created: any;
  title: string;
  description: string;
  publication_datetime: string;
  date: string
}

const EducationNewsDetailsPage = () => {
  const { slug } = useParams();
  const [newsItem, setNewsItem] = useState<NewsItem | null>(null);
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
        return await newsStore.getSingleNews(Number(slug));
    };

    const fetchImage = async (articleId:any) => {
      setIsImageLoading(true)
        const response  = await newsStore.useGetNewsPhoto(articleId);
        if (response.success) {
            setMainImage(response.data);  
        } else {
            setMainImage("");  
        }
      setIsImageLoading(false)

    };

    const fetchData = async () => {
        try {
            const response = await fetchArticle();
            const { success, data } = response;
            if (success) {
                setNewsItem(data); 
                if (data.id && data.have_main_photo) { 
                    await fetchImage(data.id); 
                }
            } else {
                setError('Error');
            }
        } catch (error) {
            setError('Error');
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, [slug]);


  // Decoding for correct view of news
  const htmlDecode = function (content: string): string {
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : (e.childNodes[0].nodeValue || "");
  };
  
	const navigate = useNavigate();
	const location = useLocation();
	const handleBack = () => {
		const backTo = location.state?.backTo || "/education/news";  
		navigate(backTo)
	  };
  return (
    <>
 	<span
					className='mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group'>
					<Button
						 onClick={handleBack}
								type='button'
								variant='back'
								tabIndex={-1}>
								{BACK_TO_NEWS}
							</Button>
				</span>
      <PageLayout isCms>
      <div className="flex justify-center items-center h-full  w-full">
        {loading ? (
          <Icon name="pageSpinner" />
        ) : error ? (
          <div className="text-red-500">{error}</div>
        ) : newsItem ? (
          <div className="flex flex-col gap-4  w-full">
                  <p className='text-sm'>{formatDateWithPoints(newsItem?.publication_datetime || newsItem.date_created.date)}</p>
            <h1 className="mb-4 ">{newsItem.title}</h1>
            <div>
              {isImageLoading && <img src={dummyNewsLargeImg} className='animate-pulse' alt="" />
              // <div className='w-ful  aspect-video mb-4 bg-gray-300 rounded'>Loading</div>
              }
              {!isImageLoading &&<img src={mainImage  || dummyNewsLargeImg} alt="" />}
                
            </div>
            <div className="pks-layout-col-md flex flex-col">
            <section
              dangerouslySetInnerHTML={{ __html: htmlDecode(newsItem.description) as string }}
              className=""
          />
                </div>
          </div>
        ) : (
          <div className="text-red-500"></div>
        )}
      </div>
    </PageLayout>
        </>
  );
};

export default EducationNewsDetailsPage;
