import { useEffect, useRef, useState } from 'react';
import {
	useNavigate,
	Link,
	Form,
	useLoaderData,
	LoaderFunctionArgs,
} from 'react-router-dom';
import {
	useForm,
	FormProvider,
	SubmitHandler,
	FieldValues,
} from 'react-hook-form';

import Button from '../../../components/shared/Button';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import Input from '../../../components/shared/Input';

import {
	EMAIL_VALIDATION_RULES,
	REQUIRED_VALIDATION_RULE,
	SUBMITTING_TEXT,
	CANCEL,
} from '../../../constants';
import { ActionResponse, DropDownItemInterface } from '../../../types';
import {
	extractMultipleErrors,
	hasNestedObjects,
	showToast,
} from '../../../utils';

import ForumMembersStore from '../services/forumMembers.store';
import OrganizationsStore from '../../organizations/services/organizations.store';
import RolesStore from '../../roles/services/roles.store';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import RegionsStore from '../../regions/services/regions.store';
import { getValue } from '@testing-library/user-event/dist/utils';

const forumMembersStore: ForumMembersStore = new ForumMembersStore();
const organizationsStore: OrganizationsStore = new OrganizationsStore();
const rolesStore: RolesStore = new RolesStore();
const regionsStore: RegionsStore = new RegionsStore();

const ForumMemberForm = () => {
	const navigate = useNavigate();

	const loaderData = useLoaderData() as ActionResponse<any> | null;
	const editFormData = loaderData?.success ? loaderData.data : undefined;


	const methods = useForm<any>({
		defaultValues: {
			email: editFormData?.email || '',
			organizationId: editFormData?.organization_id || '',
			roleIds: editFormData?.roles || [],
			firstName: editFormData?.first_name || '',
			lastName: editFormData?.last_name || '',
			phone: editFormData?.phone || '',
			regionIds: editFormData?.regions || [],
			mobilePhone: editFormData?.mobile_phone || '',
			organizationFunction: editFormData?.organization_function || '',
		},
	});

	const {
		handleSubmit,
		setError,
		getValues,
		formState: { isSubmitting },
	} = methods;


	const useRolesDropdown = (editFormData:any) => {
		return usePaginatedDropdown({
		  fetchData: (page) => rolesStore.useGetRoles(page),
		  extractItems: (data) => {
			return (data?.roles || []).map((role: any) => ({
			  id: role.id,
			  label: role.name,
			  selected: editFormData?.roles.some((selectedRole: any) => selectedRole.id === role.id),
			}));
		  },
		  dependencies: [editFormData?.roles],  
		});
	  };
	
	const useOrganizationsDropdown = () => {
		return usePaginatedDropdown({
		  fetchData: (page) => organizationsStore.useGetOrganizations(page, ''),
		  extractItems: (data) => {
			return (data?.organizations || []).map((org: any) => ({
			  id: org.id,
			  label: org.name,
			}));
		  },
		});
	};
	
	const useRegionsDropdown = (editFromData?: any) => {
		return usePaginatedDropdown({
			fetchData: (page) => regionsStore.useGetRegions(page),
			extractItems: (data) => {
				return (data?.regions || []).map((region: any) => ({
					id: region.id,
					label: region.name
				}))
			}
		})
	}

	const { finalItems: finalRoles, isLoadingRef: isLoadingRolesRef } = useRolesDropdown(editFormData);
	const { finalItems: finalOrganizations, isLoadingRef: isLoadingOrganizationsRef } = useOrganizationsDropdown();
	const { finalItems: finalRegions, isLoadingRef: isLoadingRegionsRef } = useRegionsDropdown()


	const handleError = (message: any) => {
		if (hasNestedObjects(message)) {
			const errorMessages = extractMultipleErrors(message);
			for (const key in errorMessages) {
				setError(key as any, {
					type: 'backend',
					message: errorMessages[key],
				});
			}
		} else {
			showToast(message, false, true);
		}
	};

	// const handleRoles = (roles: any) => {
	// 	// Check if it's an array
	// 	if (Array.isArray(roles)) {
	// 		// Case 1: Array of objects (with `id` field)
	// 		if (roles.length > 0 && typeof roles[0] === 'object') {
	// 			const roleIds = roles.map((role) => role.id);
	// 			return roleIds;
	// 		}
	// 		// Case 2: Simple array of numbers (IDs)
	// 		if (roles.length > 0 && typeof roles[0] === 'number') {
	// 			return roles;
	// 		}

	// 		return []; // Return an empty array if it's an empty array or unexpected content
	// 	}

	// 	return []; // Return an empty array if roles is not an array
	// };



	const onSubmit: SubmitHandler<FieldValues> = async (data) => {
		let formData = new FormData();

		Object.keys(data).forEach((key) => {
			if (
				![
					'regionIds',
					'roleIds',
				].includes(key)
			) {
				formData.append(key, data[key]);
			}
		});
		
		data.regionIds.forEach((regionId: any) => {
			formData.append('regionIds[]', typeof regionId === "number" ? regionId : regionId.id);
		});
		
		data.roleIds.forEach((roleId: any) => {
			formData.append('roleIds[]', typeof roleId === "number" ? roleId : roleId.id);
		});
		


		const response = editFormData?.id
			? await forumMembersStore.updateForumMember(
					editFormData.id,
					formData
			  )
			: await forumMembersStore.addForumMember(formData);

		const { success, message } = response;

		if (success) {
			showToast(message, success);
			navigate(-1);
		} else {
			// TODO check again because its hardcoded...
			if (message === 'Account with email already exists.') {
				setError('email', {
					type: 'manual',
					message: 'Već postoji član sa ovom email adresom',
				});
			}
			handleError("Već postoji član sa ovom email adresom");
		}
	};

	return (
		<>
			<FormProvider {...methods}>
				<Form
					method='post'
					className='pks-layout-col-md'
					onSubmit={handleSubmit(onSubmit)}>
					{/* Form Fields */}
					<div className='pks-layout-col'>
						{/* Organizations Dropdown Field */}
						<DropDownSelect
							id='organizationId'
							name='organizationId'
							label='Institucija*'
							placeholder='Izaberi instituciju'
							options={finalOrganizations}
							validationRules={REQUIRED_VALIDATION_RULE}
							isLoading={isLoadingOrganizationsRef.current}
						/>

						{/* First Name Input Filed */}
						<div className='flex flex-col gap-2 sm:flex-row'>
							<div className='flex-1'>
								<Input
									type='text'
									id='firstName'
									name='firstName'
									label='Ime*'
									placeholder='Unesi ime'
									autoComplete='off'
									validationRules={REQUIRED_VALIDATION_RULE}
								/>
							</div>

							{/* Last Name Input Field */}
							<div className='flex-1'>
								<Input
									type='text'
									id='lastName'
									name='lastName'
									label='Prezime*'
									placeholder='Unesi prezime'
									autoComplete='off'
									validationRules={REQUIRED_VALIDATION_RULE}
								/>
							</div>
						</div>

						{/* Email Input Field */}
						<Input
							type='text'
							id='email'
							name='email'
							label='Email*'
							placeholder='Unesi email'
							autoComplete='off'
							validationRules={EMAIL_VALIDATION_RULES}
						/>


						{/* Roled Dropdown Field */}
						<DropDownSelect
							id='roleIds'
							name='roleIds'
							label='Uloga*'
							placeholder='Izaberi ulogu'
							options={finalRoles}
							isLoading={isLoadingRolesRef.current}
							validationRules={REQUIRED_VALIDATION_RULE}
							multiple
						/>
							<div className="">
									{/* Regions Dropdown Field */}
									<DropDownSelect
										id='regionIds'
										name='regionIds'
										label='Region*'
										multiple
										placeholder='Izaberi region'
										options={finalRegions}
										validationRules={REQUIRED_VALIDATION_RULE}
										isLoading={isLoadingRegionsRef.current}
									/>
								</div>
						{/* Phone Input Field */}
						<Input
							type='text'
							id='phone'
							name='phone'
							label='Telefon'
							placeholder='Unesi telefon'
							autoComplete='off'
							numeric
						/>

						{/* Mobile Phone Input Field */}
						<Input
							type='text'
							id='mobilePhone'
							name='mobilePhone'
							label='Mobilni telefon*'
							placeholder='Unesi mobilni telefon'
							autoComplete='off'
							validationRules={REQUIRED_VALIDATION_RULE}
							numeric
						/>

						{/* Organization Function Input Field */}
						<Input
							type='text'
							id='function'
							name='organizationFunction'
							label='Funkcija*'
							placeholder='Unesi funkciju'
							autoComplete='off'
							validationRules={REQUIRED_VALIDATION_RULE}
						/>
					</div>

					{/* Form Actions */}
					<div className='w-full inline-flex flex-wrap gap-4'>
						{/* Submit Button */}
						<Button className='flex-grow' disabled={isSubmitting}>
							{isSubmitting
								? SUBMITTING_TEXT
								: editFormData
								? 'Izmeni člana'
								: 'Dodaj člana'}
						</Button>
						{/* Back Button */}
					<span className="flex-auto sm:flex-none focus:outline-none group">
						<Button
							onClick={() => navigate(-1)}
							type='button'
							variant='secondary'
							wide
							tabIndex={-1}>
							{CANCEL}
						</Button>
					</span>
					</div>
				</Form>
			</FormProvider>
		</>
	);
};

export default ForumMemberForm;

export async function loader({
	params,
}: LoaderFunctionArgs): Promise<ActionResponse<any> | null> {
	const slug = Number(params.slug);

	if (slug) {
		const data = await forumMembersStore.preloadForumMember(slug);
		const roles = await forumMembersStore.getForumMemberRole(slug);

		if (data && data.success) {
			data.data.roles = [...roles.data.roles];
		}

		return data;
	}

	return null;
}
