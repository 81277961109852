import * as ICONS from './Icons';

interface IconProps {
	name: keyof typeof ICONS;
	className?: string;
	tabIndex?: number;
}

const Icon: React.FC<IconProps> = ({ name, className, tabIndex }) => {
	const Icon = ICONS[name];

	if (!Icon) {
		return null;
	}

	return (
    <span tabIndex={tabIndex} className={className}>
      {Icon}
    </span>
  );
};

export default Icon;
