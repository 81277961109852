import React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import DatePicker from 'react-multi-date-picker';
import '../../../assets/styles/react-multi-date-picker/colors/primary.css';
import useInputContext from '../../../hooks/useInputContext';

import { DATE_FORMAT } from '../../../constants';

interface CustomDatePickerProps {
  id: string;
  name: string;
  label: string;
  maxDate?: any;
  validationRules?: RegisterOptions;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
	id,
	name,
	label,
	maxDate,
	validationRules,
}) => {
	const { control, error, isSubmitted } = useInputContext(name);

	return (
		<div>
			<label htmlFor={id} className='block mb-1 hover:cursor-pointer'>
				{label}
			</label>
			<Controller
				control={control}
				name={name}
				rules={validationRules}
				render={({ field: { value, onChange } }) => {
					return (
            <DatePicker
              maxDate={maxDate}
              id={id}
              value={value || ''}
              onChange={(date) => {
                onChange(date?.format(DATE_FORMAT));
              }}
              format={DATE_FORMAT}
              placeholder={DATE_FORMAT}
              containerStyle={{
                width: '100%',
              }}
              className="primary"
              inputClass={`pks-input ${error ? 'pks-input-error' : isSubmitted ? 'pks-input-success' : 'pks-input-initial'}`}
            />
          );
				}}
			/>
			{/* Error */}
			{error && <div className='text-danger'>{error.message}</div>}
		</div>
	);
};

export default CustomDatePicker;
