import { useParams, useSearchParams } from 'react-router-dom';
import DropDownSelect from '../../../components/shared/DropdownSelect';
import Table from '../../../components/shared/Table';
import Pagination from '../../../components/shared/Pagination';
import { DOCUMENTATION_TYPE_OPTIONS } from '../../../constants';
import { useEffect, useState } from 'react';
import NewsStore from '../../news/services/news.store';
import Icon from '../../../components/shared/Icon';
import { useModal } from '../../../context/modal-context';
import DocumentModalContent from './DocumentModalContent';

const newsStore = new NewsStore();
const TABLE_HEADINGS = [undefined, 'Dokument', 'Opis dokumenta', 'Datum unosa'];

const EducationReports = (): React.JSX.Element | null => {
	const { slug } = useParams(); 
	const [searchParams, setSearchParams] = useSearchParams();
	const page = Number(searchParams.get('page')) || 1;
	const region = searchParams.get('regionIds[]') || '';
	const organization = searchParams.get('organizationIds[]') || '';
	const [currentType, setCurrentType] = useState<string>(slug || 'regular_sessions');
	const [searchValue, setSearchValue] = useState(searchParams.get('name') || '');

	const { data: documentsQueryData, isFetching } = newsStore.useGetDocuments(
		page, currentType, searchValue, region, organization
	);

	const educationReports = documentsQueryData?._embedded?.fraudcases || [];
	const totalPages = documentsQueryData?._page_count || 1;

	useEffect(() => {
		setSearchParams(searchParams); 
	}, [page, currentType, region, organization, searchParams]);

	useEffect(() => {
		if (slug) {
			setCurrentType(slug);
		}
	}, [slug]);

	const handlePageChange = (newPage: number) => {
		if (newPage !== page) {
			searchParams.set('page', newPage.toString());
			setSearchParams(searchParams);
		}
	};

	// const handleFilterChange = (selectedFilter: string) => {
	// 	setCurrentType(selectedFilter);
	// 	searchParams.set('page', '1');
	// 	setSearchParams(searchParams);
	// };


	// const selectedOptionIndex = DOCUMENTATION_TYPE_OPTIONS.findIndex(
	// 	(option) => option.id === currentType
	// );

	const {openModal, closeModal} = useModal()

	const rowClick = (e:any, rowItem:any) => {
		e.stopPropagation()
		openModal(<DocumentModalContent document={rowItem} />, "Dokument");
	}

	return (
		<div className='pks-layout-col-md'>
				
			<div className='max-w-xs'>
				{/* <DropDownSelect
					label='Izaberi tip dokumentacije'
					options={DOCUMENTATION_TYPE_OPTIONS}
					onChange={handleFilterChange}
					selectedOptionIndex={selectedOptionIndex > -1 ? selectedOptionIndex : undefined}
				/> */}
			</div>
			{isFetching &&
				<div className="w-full h-full flex items-center justify-center ">
					<Icon name='pageSpinner' />
				</div>
				}
			{!isFetching && educationReports.length > 0 &&
				<>
			<Table headings={TABLE_HEADINGS} rows={educationReports} handleRowClick={rowClick} />
			<Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
			</>	
			}
			{!isFetching && educationReports.length === 0 &&
			<p>Nažalost, ne postoje podaci za ovu vrstu dokumenta.</p>}
		</div>
	);
};

export default EducationReports;
